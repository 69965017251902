import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ConfirmationService, MessageService, SelectItem} from 'primeng/api';
import {PpapPhaseQuestion} from "../../models/ppap-phase-question";
import {PpapPhaseQuestionService} from "../../services/ppap-phase-question.service";

@Component({
	selector: 'ppap-phase-question',
	templateUrl: './ppap-phase-question.component.html',
	styleUrls: ['./ppap-phase-question.component.scss']
})
export class PpapPhaseQuestionComponent implements OnInit {
	cols: any;
	assessmentQuestions: any[];
	phase: SelectItem[];
	phaseValue: any;
	section: SelectItem[];
	sortSequence: number;
	question: string;
	mandatorycheckbox: any;
	sectionOld: string;
	seqOld: number;
	activeOld: any
	mandatoryOld: any;
	questionOld: string;
	sectionFromPpapPhase: SelectItem[]=[];
	sectionForTable: SelectItem[]=[];
	loading: boolean;
	selectedSectionValue: any;
	ppapAssessmentQuestion: PpapPhaseQuestion;
	sectionId: any;
	clonedQuestion: { [questionId: number]: PpapPhaseQuestion; } = {};
	questionDataList: any[];
	questionDataMapZero: Map<Number, String> = new Map<Number, String>();
	questionDataMapOne: Map<Number, String> = new Map<Number, String>();
	questionDataMapTwo: Map<Number, String> = new Map<Number, String>();
	questionDataMapThree: Map<Number, String> = new Map<Number, String>();
	questionDataZeroList: Array<Map<Number, String>> = new Array<Map<Number, String>>();
	questionDataOneList: Array<Map<Number, String>> = new Array<Map<Number, String>>();
	questionDataTwoList: Array<Map<Number, String>> = new Array<Map<Number, String>>();
	questionDataThreeList: Array<Map<Number, String>> = new Array<Map<Number, String>>();
	seqDataMapZero: Map<Number, Number> = new Map<Number, Number>();
	seqDataMapOne: Map<Number, Number> = new Map<Number, Number>();
	seqDataMapTwo: Map<Number, Number> = new Map<Number, Number>();
	seqDataMapThree: Map<Number, Number> = new Map<Number, Number>();
	seqDataZeroList: Array<Map<Number, Number>> = new Array<Map<Number, Number>>();
	seqDataOneList: Array<Map<Number, Number>> = new Array<Map<Number, Number>>();
	seqDataTwoList: Array<Map<Number, Number>> = new Array<Map<Number, Number>>();
	seqDataThreeList: Array<Map<Number, Number>> = new Array<Map<Number, Number>>();
	sectionExistsZero: boolean;
	sectionExistsOne: boolean;
	sectionExistsTwo: boolean;
	sectionExistsThree: boolean;
	seqExistsZero: boolean;
	seqExistsOne: boolean;
	seqExistsTwo: boolean;
	seqExistsThree: boolean;
	phaseSectionDuplicate: boolean;

	constructor(private router: Router, private ppapPhaseQuestionService: PpapPhaseQuestionService,
				private confirmationService: ConfirmationService, private messageService: MessageService) {
	}

	ngOnInit() {
		this.ppapAssessmentQuestion = new PpapPhaseQuestion(this.ppapAssessmentQuestion);
		this.phase = [
			{label: 'Phase 0', value: {id: 1, name: 'Phase 0', code: 'P1'}},
			{label: 'Phase 1', value: {id: 2, name: 'Phase 1', code: 'P2'}},
			{label: 'Phase 2', value: {id: 3, name: 'Phase 2', code: 'P3'}},
			{label: 'Phase 3', value: {id: 4, name: 'Phase 3', code: 'P4'}},
		];
		this.getAllData();
		this.phaseSectionDuplicate=false;
	}

	onClose() {
		this.resetFields();
	}

	private resetFields() {
		this.phaseValue = null;
		this.selectedSectionValue = null;
		this.sortSequence = null;
		this.question='';
		this.mandatorycheckbox=false;
	}

	getSectionData() {
		this.sectionFromPpapPhase = [];
		this.ppapPhaseQuestionService.getSectionData(this.phaseValue.name).subscribe(value=>{
			let iterator;
			for (iterator = 0; iterator < value.length; iterator++) {
				this.sectionFromPpapPhase.push({label: value[iterator], value: value[iterator]});
			}
		});
	}

	getSectionForTable(phase: any) {
		this.sectionForTable = [];
		this.ppapPhaseQuestionService.getSectionData(phase).subscribe(value=>{
			let iterator;
			for (iterator = 0; iterator < value.length; iterator++) {
				this.sectionForTable.push({label: value[iterator], value: value[iterator]});
			}
		});
	}

	getAllData(){
		this.loading = true;
		this.questionDataZeroList = new Array<Map<Number, String>>();
		this.questionDataOneList = new Array<Map<Number, String>>();
		this.questionDataTwoList = new Array<Map<Number, String>>();
		this.questionDataThreeList = new Array<Map<Number, String>>();
		this.seqDataZeroList = new Array<Map<Number, Number>>();
		this.seqDataOneList = new Array<Map<Number, Number>>();
		this.seqDataTwoList = new Array<Map<Number, Number>>();
		this.seqDataThreeList = new Array<Map<Number, Number>>();
		this.ppapPhaseQuestionService.getAllData().subscribe(resp=>{
			this.assessmentQuestions = resp;
			this.questionDataList = resp;
			this.loading = false;
			for (let data of this.assessmentQuestions) {
				this.questionDataMapZero =  new Map<Number, string>();
				this.questionDataMapOne =  new Map<Number, string>();
				this.questionDataMapTwo =  new Map<Number, string>();
				this.questionDataMapThree =  new Map<Number, string>();
				this.seqDataMapZero = new Map<Number, Number>();
				this.seqDataMapOne = new Map<Number, Number>();
				this.seqDataMapTwo = new Map<Number, Number>();
				this.seqDataMapThree = new Map<Number, Number>();
				if (data.isActive === 'Yes') {
					data.isActive = true;
				} else {
					data.isActive = false;
				}
				if (data.isMandatory === 'Yes') {
					data.isMandatory = true;
				} else {
					data.isMandatory = false;
				}
				if(data.phase === 'Phase 0'){
					this.questionDataMapZero.set(data.sectionId, data.questionDesc);
					this.seqDataMapZero.set(data.sectionId, data.sortSequence)
				} else if(data.phase === 'Phase 1') {
					this.questionDataMapOne.set(data.sectionId, data.questionDesc);
					this.seqDataMapOne.set(data.sectionId, data.sortSequence)
				} else if(data.phase === 'Phase 2') {
					this.questionDataMapTwo.set(data.sectionId, data.questionDesc);
					this.seqDataMapTwo.set(data.sectionId, data.sortSequence)
				} else if(data.phase === 'Phase 3') {
					this.questionDataMapThree.set(data.sectionId, data.questionDesc);
					this.seqDataMapThree.set(data.sectionId, data.sortSequence)
				}
				this.questionDataZeroList.push(this.questionDataMapZero);
				this.questionDataOneList.push(this.questionDataMapOne);
				this.questionDataTwoList.push(this.questionDataMapTwo);
				this.questionDataThreeList.push(this.questionDataMapThree);
				this.seqDataZeroList.push(this.seqDataMapZero);
				this.seqDataOneList.push(this.seqDataMapOne);
				this.seqDataTwoList.push(this.seqDataMapTwo);
				this.seqDataThreeList.push(this.seqDataMapThree);
			}
		});
	}


	onRowDelete(assessmentQuestions: PpapPhaseQuestion) {
		this.confirmationService.confirm({
			message: 'Do you want to delete this record?',
			header: 'Delete Confirmation',
			icon: 'fa fa-info-circle',
			accept: () => {
				this.loading = true;
				this.ppapPhaseQuestionService.deleteData(assessmentQuestions.questionId).subscribe(resp => {
					this.assessmentQuestions = this.assessmentQuestions.filter(i => i.questionId !== resp.genericInt);
					this.loading = false;
					this.getAllData();
				});
			},
			reject: () => {
				this.loading = false;
			}
		});
	}

	getSectionId(phaseValue: any, selectedSectionValue: any) {
		this.ppapPhaseQuestionService.getSectionId(phaseValue,selectedSectionValue).subscribe(value=>{
			this.sectionId = value;
		});
	}

	onRowEditCancel(assessmentQuestions: PpapPhaseQuestion, index: number) {
		if(this.clonedQuestion[assessmentQuestions.questionId] !== undefined) {
			this.questionDataList[index] = this.clonedQuestion[assessmentQuestions.questionId];
			delete this.clonedQuestion[assessmentQuestions.questionId];
		}
		this.getAllData();
	}

	onRowEditInit(phaseAssessmentQuestions: PpapPhaseQuestion) {
		this.clonedQuestion[phaseAssessmentQuestions.questionId] = {...phaseAssessmentQuestions};
		this.sectionOld = phaseAssessmentQuestions.sectionName;
		this.seqOld = phaseAssessmentQuestions.sortSequence;
		this.questionOld = phaseAssessmentQuestions.questionDesc;
		this.activeOld = phaseAssessmentQuestions.isActive;
		this.mandatoryOld = phaseAssessmentQuestions.isMandatory;
		for (let iterator = 0; iterator < this.questionDataZeroList.length; iterator++) {
			if (this.questionDataZeroList[iterator].get(phaseAssessmentQuestions.sectionId) === phaseAssessmentQuestions.questionDesc) {
				this.questionDataZeroList = this.questionDataZeroList.filter(i=>i.get(phaseAssessmentQuestions.sectionId)!==phaseAssessmentQuestions.questionDesc);
			}
		}
		for (let iterator = 0; iterator < this.seqDataZeroList.length; iterator++) {
			if (this.seqDataZeroList[iterator].get(phaseAssessmentQuestions.sectionId) === phaseAssessmentQuestions.sortSequence) {
				this.seqDataZeroList = this.seqDataZeroList.filter(i=>i.get(phaseAssessmentQuestions.sectionId)!==phaseAssessmentQuestions.sortSequence);
			}
		}
		for (let iterator = 0; iterator < this.questionDataOneList.length; iterator++) {
			if (this.questionDataOneList[iterator].get(phaseAssessmentQuestions.sectionId) === phaseAssessmentQuestions.questionDesc) {
				this.questionDataOneList = this.questionDataOneList.filter(i=>i.get(phaseAssessmentQuestions.sectionId)!==phaseAssessmentQuestions.questionDesc);
			}
		}
		for (let iterator = 0; iterator < this.seqDataOneList.length; iterator++) {
			if (this.seqDataOneList[iterator].get(phaseAssessmentQuestions.sectionId) === phaseAssessmentQuestions.sortSequence) {
				this.seqDataOneList = this.seqDataOneList.filter(i=>i.get(phaseAssessmentQuestions.sectionId)!==phaseAssessmentQuestions.sortSequence);
			}
		}
		for (let iterator = 0; iterator < this.questionDataTwoList.length; iterator++) {
			if (this.questionDataTwoList[iterator].get(phaseAssessmentQuestions.sectionId) === phaseAssessmentQuestions.questionDesc) {
				this.questionDataTwoList = this.questionDataTwoList.filter(i=>i.get(phaseAssessmentQuestions.sectionId)!==phaseAssessmentQuestions.questionDesc);
			}
		}
		for (let iterator = 0; iterator < this.seqDataTwoList.length; iterator++) {
			if (this.seqDataTwoList[iterator].get(phaseAssessmentQuestions.sectionId) === phaseAssessmentQuestions.sortSequence) {
				this.seqDataTwoList = this.seqDataTwoList.filter(i=>i.get(phaseAssessmentQuestions.sectionId)!==phaseAssessmentQuestions.sortSequence);
			}
		}
		for (let iterator = 0; iterator < this.questionDataThreeList.length; iterator++) {
			if (this.questionDataThreeList[iterator].get(phaseAssessmentQuestions.sectionId) === phaseAssessmentQuestions.questionDesc) {
				this.questionDataThreeList = this.questionDataThreeList.filter(i=>i.get(phaseAssessmentQuestions.sectionId)!==phaseAssessmentQuestions.questionDesc);
			}
		}
		for (let iterator = 0; iterator < this.seqDataThreeList.length; iterator++) {
			if (this.seqDataThreeList[iterator].get(phaseAssessmentQuestions.sectionId) === phaseAssessmentQuestions.sortSequence) {
				this.seqDataThreeList = this.seqDataThreeList.filter(i=>i.get(phaseAssessmentQuestions.sectionId)!==phaseAssessmentQuestions.sortSequence);
			}
		}
	}

	onCreate() {
		for (let data of this.assessmentQuestions) {
			this.questionDataMapZero =  new Map<Number, string>();
			this.questionDataMapOne =  new Map<Number, string>();
			this.questionDataMapTwo =  new Map<Number, string>();
			this.questionDataMapThree =  new Map<Number, string>();
			this.seqDataMapZero = new Map<Number, Number>();
			this.seqDataMapOne = new Map<Number, Number>();
			this.seqDataMapTwo = new Map<Number, Number>();
			this.seqDataMapThree = new Map<Number, Number>();
			if (data.isActive === 'Yes') {
				data.isActive = true;
			} else {
				data.isActive = false;
			}
			if (data.isMandatory === 'Yes') {
				data.isMandatory = true;
			} else {
				data.isMandatory = false;
			}
			if(data.phase === 'Phase 0'){
				this.questionDataMapZero.set(data.sectionId, data.questionDesc);
				this.seqDataMapZero.set(data.sectionId, data.sortSequence)
			} else if(data.phase === 'Phase 1') {
				this.questionDataMapOne.set(data.sectionId, data.questionDesc);
				this.seqDataMapOne.set(data.sectionId, data.sortSequence)
			} else if(data.phase === 'Phase 2') {
				this.questionDataMapTwo.set(data.sectionId, data.questionDesc);
				this.seqDataMapTwo.set(data.sectionId, data.sortSequence)
			} else if(data.phase === 'Phase 3') {
				this.questionDataMapThree.set(data.sectionId, data.questionDesc);
				this.seqDataMapThree.set(data.sectionId, data.sortSequence)
			}
			this.questionDataZeroList.push(this.questionDataMapZero);
			this.questionDataOneList.push(this.questionDataMapOne);
			this.questionDataTwoList.push(this.questionDataMapTwo);
			this.questionDataThreeList.push(this.questionDataMapThree);
			this.seqDataZeroList.push(this.seqDataMapZero);
			this.seqDataOneList.push(this.seqDataMapOne);
			this.seqDataTwoList.push(this.seqDataMapTwo);
			this.seqDataThreeList.push(this.seqDataMapThree);
		}
		this.sectionExistsZero = false; this.sectionExistsOne = false; this.sectionExistsTwo = false; this.sectionExistsThree = false;
		this.seqExistsZero = false; this.seqExistsOne = false; this.seqExistsTwo = false; this.seqExistsThree = false;
		if (this.question === undefined || this.question === '' || this.sortSequence === undefined || this.phaseValue === undefined
			|| this.selectedSectionValue === undefined || this.sortSequence === null || this.phaseValue === null || this.selectedSectionValue === null) {
			this.messageService.add({key: 'ppapSectionToast',severity: 'error',summary: 'Error',detail: 'Please Fill the Required Fields!', life:5000});
		}
		if (this.phaseValue !== undefined && (this.selectedSectionValue !== undefined && this.selectedSectionValue !== '') &&
			(this.sortSequence !== undefined && this.sortSequence !== null) &&
			(this.question !== undefined && this.question !== null)) {
			if (this.phaseValue.name === 'Phase 0') {
				for (let iterator = 0; iterator < this.questionDataZeroList.length; iterator++) {
					if (this.questionDataZeroList[iterator] !== undefined && this.questionDataZeroList[iterator].get(this.sectionId) === this.question) {
						this.sectionExistsZero = true;
						this.messageService.add({key: 'ppapSectionToast',severity: 'error',summary: 'Error',detail: 'Phase and Question combination already exists!', life: 5000});
						break;
					} else {
						this.sectionExistsZero = false;
						if (this.seqDataZeroList[iterator] !== undefined && this.seqDataZeroList[iterator].get(this.sectionId) === this.sortSequence) {
							this.seqExistsZero = true;
							this.messageService.add({key: 'ppapSequenceToast',severity: 'error',summary: 'Error',detail: 'Phase and Sort Sequence combination already exists!', life: 5000});
							break;
						} else {
							this.seqExistsZero = false;
						}
					}
				}
			} else if (this.phaseValue.name === 'Phase 1') {
				for (let iterator = 0; iterator < this.questionDataOneList.length; iterator++) {
					if (this.questionDataOneList[iterator] !== undefined && this.questionDataOneList[iterator].get(this.sectionId) === this.question) {
						this.sectionExistsOne = true;
						this.messageService.add({key: 'ppapSectionToast',severity: 'error',summary: 'Error',detail: 'Phase and Question combination already exists!', life: 5000});
						break;
					} else {
						this.sectionExistsOne = false;
						if (this.seqDataOneList[iterator] !== undefined && this.seqDataOneList[iterator].get(this.sectionId) === this.sortSequence) {
							this.seqExistsOne = true;
							this.messageService.add({key: 'ppapSequenceToast',severity: 'error',summary: 'Error',detail: 'Phase and Sort Sequence combination already exists!', life: 5000});
							break;
						} else {
							this.seqExistsOne = false;
						}
					}
				}
			} else if (this.phaseValue.name === 'Phase 2') {
				for (let iterator = 0; iterator < this.questionDataTwoList.length; iterator++) {
					if (this.questionDataTwoList[iterator] !== undefined && this.questionDataTwoList[iterator].get(this.sectionId) === this.question) {
						this.sectionExistsTwo = true;
						this.messageService.add({key: 'ppapSectionToast',severity: 'error',summary: 'Error',detail: 'Phase and Question combination already exists!', life: 5000});
						break;
					} else {
						this.sectionExistsTwo = false;
						if (this.seqDataTwoList[iterator] !== undefined && this.seqDataTwoList[iterator].get(this.sectionId) === this.sortSequence) {
							this.seqExistsTwo = true;
							this.messageService.add({key: 'ppapSequenceToast',severity: 'error',summary: 'Error',detail: 'Phase and Sort Sequence combination already exists!', life: 5000});
							break;
						} else {
							this.seqExistsTwo = false;
						}
					}
				}
			} else if (this.phaseValue.name === 'Phase 3') {
				for (let iterator = 0; iterator < this.questionDataThreeList.length; iterator++) {
					if (this.questionDataThreeList[iterator] !== undefined && this.questionDataThreeList[iterator].get(this.sectionId) === this.question) {
						this.sectionExistsThree = true;
						this.messageService.add({key: 'ppapSectionToast',severity: 'error',summary: 'Error',detail: 'Phase and Question combination already exists!', life: 5000});
						break;
					} else {
						this.sectionExistsThree = false;
						if (this.seqDataThreeList[iterator] !== undefined && this.seqDataThreeList[iterator].get(this.sectionId) === this.sortSequence) {
							this.seqExistsThree = true;
							this.messageService.add({key: 'ppapSequenceToast',severity: 'error',summary: 'Error',detail: 'Phase and Sort Sequence combination already exists!', life: 5000});
							break;
						} else {
							this.seqExistsThree = false;
						}
					}
				}
			}
			if (!this.sectionExistsZero && !this.sectionExistsOne && !this.sectionExistsTwo && !this.sectionExistsThree
				&& !this.seqExistsZero && !this.seqExistsOne && !this.seqExistsTwo && !this.seqExistsThree) {
				if (this.question !== undefined && this.question.length !== 0 && this.sortSequence > 0) {
					this.ppapAssessmentQuestion.phase = this.phaseValue.name;
					this.ppapAssessmentQuestion.sectionName = this.selectedSectionValue.trim();
					this.ppapAssessmentQuestion.sortSequence = this.sortSequence;
					this.ppapAssessmentQuestion.questionDesc = this.question;
					this.ppapAssessmentQuestion.sectionId = this.sectionId;
					if (this.mandatorycheckbox) {
						this.ppapAssessmentQuestion.isMandatory = 'Yes';
					} else {
						this.ppapAssessmentQuestion.isMandatory = 'No';
					}
					this.loading = true;
					this.ppapPhaseQuestionService.saveData(this.ppapAssessmentQuestion).subscribe(resp => {
						this.loading = false;
						this.resetFields();
						this.getAllData();
					}, error => {
						this.loading = false;
					});
				} else {
					this.messageService.add({key: 'ppapSectionToast',severity: 'error',summary: 'Error',detail: 'Please Fill the Required Fields!', life: 5000});
				}
			}
		}
	}

	onRowEditSave(phaseAssessmentQuestion: PpapPhaseQuestion) {
		this.sectionExistsZero = false; this.sectionExistsOne = false; this.sectionExistsTwo = false; this.sectionExistsThree = false;
		this.seqExistsZero = false; this.seqExistsOne = false; this.seqExistsTwo = false; this.seqExistsThree = false;
		if (this.sectionOld === phaseAssessmentQuestion.sectionName && this.seqOld === phaseAssessmentQuestion.sortSequence &&
			this.questionOld === phaseAssessmentQuestion.questionDesc && this.activeOld === phaseAssessmentQuestion.isActive &&
			this.mandatoryOld === phaseAssessmentQuestion.isMandatory) {
			this.messageService.add({key: 'ppapSectionToast',severity: 'info',summary: 'Information',detail: 'No Changes!',life: 5000
			});
		} else {
			for (let iterator = 0; iterator < this.assessmentQuestions.length; iterator++) {
				if (this.assessmentQuestions[iterator].sortSequence === phaseAssessmentQuestion.sortSequence ||
					this.assessmentQuestions[iterator].questionDesc === phaseAssessmentQuestion.questionDesc) {
					if (phaseAssessmentQuestion.phase === this.assessmentQuestions[iterator].phase &&
						phaseAssessmentQuestion.sectionName === this.assessmentQuestions[iterator].sectionName) {
						if (phaseAssessmentQuestion.sectionId !== this.assessmentQuestions[iterator].sectionId) {
							this.phaseSectionDuplicate = true;
							if (this.assessmentQuestions[iterator].sortSequence === phaseAssessmentQuestion.sortSequence &&
								this.assessmentQuestions[iterator].questionDesc !== phaseAssessmentQuestion.questionDesc &&
								phaseAssessmentQuestion.sectionName !== this.assessmentQuestions[iterator].sectionName) {
								this.messageService.add({
									key: 'ppapSectionToast',
									severity: 'error',
									summary: 'Error',
									detail: 'Phase and Sort Sequence combination already exists!',
									life: 5000
								});
							} else if (this.assessmentQuestions[iterator].sortSequence !== phaseAssessmentQuestion.sortSequence &&
								this.assessmentQuestions[iterator].questionDesc === phaseAssessmentQuestion.questionDesc) {
								this.messageService.add({
									key: 'ppapSectionToast',
									severity: 'error',
									summary: 'Error',
									detail: 'Phase and Question combination already exists!',
									life: 5000
								});
							} else {
								this.messageService.add({
									key: 'ppapSectionToast',
									severity: 'error',
									summary: 'Error',
									detail: 'Phase,Section Name and Question/Sort Sequence combination already exists!',
									life: 5000
								});
							}
							break;
						} else {
							this.phaseSectionDuplicate = false;
						}
					} else {
						this.phaseSectionDuplicate = false;
					}
				} else {
					this.phaseSectionDuplicate = false;
				}
			}
			if (!this.phaseSectionDuplicate) {
				if (phaseAssessmentQuestion.phase === 'Phase 0') {
					for (let iterator = 0; iterator < this.questionDataZeroList.length; iterator++) {
						if (this.questionDataZeroList[iterator] !== undefined && this.questionDataZeroList[iterator].get(phaseAssessmentQuestion.sectionId) === phaseAssessmentQuestion.questionDesc) {
							this.sectionExistsZero = true;
							this.messageService.add({
								key: 'ppapSectionToast',
								severity: 'error',
								summary: 'Error',
								detail: 'Phase and Question combination already exists!',
								life: 5000
							});
							break;
						} else {
							this.sectionExistsZero = false;
							if (this.seqDataZeroList[iterator] !== undefined && this.seqDataZeroList[iterator].get(phaseAssessmentQuestion.sectionId) === phaseAssessmentQuestion.sortSequence) {
								this.seqExistsZero = true;
								this.messageService.add({
									key: 'ppapSequenceToast',
									severity: 'error',
									summary: 'Error',
									detail: 'Phase and Sort Sequence combination already exists!',
									life: 5000
								});
								break;
							} else {
								this.seqExistsZero = false;
							}
						}
					}
				} else if (phaseAssessmentQuestion.phase === 'Phase 1') {
					for (let iterator = 0; iterator < this.questionDataOneList.length; iterator++) {
						if (this.questionDataOneList[iterator] !== undefined && this.questionDataOneList[iterator].get(phaseAssessmentQuestion.sectionId) === phaseAssessmentQuestion.questionDesc) {
							this.sectionExistsOne = true;
							this.messageService.add({
								key: 'ppapSectionToast',
								severity: 'error',
								summary: 'Error',
								detail: 'Phase and Question combination already exists!',
								life: 5000
							});
							break;
						} else {
							this.sectionExistsOne = false;
							if (this.seqDataOneList[iterator] !== undefined && this.seqDataOneList[iterator].get(phaseAssessmentQuestion.sectionId) === phaseAssessmentQuestion.sortSequence) {
								this.seqExistsOne = true;
								this.messageService.add({
									key: 'ppapSequenceToast',
									severity: 'error',
									summary: 'Error',
									detail: 'Phase and Sort Sequence combination already exists!',
									life: 5000
								});
								break;
							} else {
								this.seqExistsOne = false;
							}
						}
					}
				} else if (phaseAssessmentQuestion.phase === 'Phase 2') {
					for (let iterator = 0; iterator < this.questionDataTwoList.length; iterator++) {
						if (this.questionDataTwoList[iterator] !== undefined && this.questionDataTwoList[iterator].get(phaseAssessmentQuestion.sectionId) === phaseAssessmentQuestion.questionDesc) {
							this.sectionExistsTwo = true;
							this.messageService.add({
								key: 'ppapSectionToast',
								severity: 'error',
								summary: 'Error',
								detail: 'Phase and Question combination already exists!',
								life: 5000
							});
							break;
						} else {
							this.sectionExistsTwo = false;
							if (this.seqDataTwoList[iterator] !== undefined && this.seqDataTwoList[iterator].get(phaseAssessmentQuestion.sectionId) === phaseAssessmentQuestion.sortSequence) {
								this.seqExistsTwo = true;
								this.messageService.add({
									key: 'ppapSequenceToast',
									severity: 'error',
									summary: 'Error',
									detail: 'Phase and Sort Sequence combination already exists!',
									life: 5000
								});
								break;
							} else {
								this.seqExistsTwo = false;
							}
						}
					}
				} else if (phaseAssessmentQuestion.phase === 'Phase 3') {
					for (let iterator = 0; iterator < this.questionDataThreeList.length; iterator++) {
						if (this.questionDataThreeList[iterator] !== undefined && this.questionDataThreeList[iterator].get(phaseAssessmentQuestion.sectionId) === phaseAssessmentQuestion.questionDesc) {
							this.sectionExistsThree = true;
							this.messageService.add({
								key: 'ppapSectionToast',
								severity: 'error',
								summary: 'Error',
								detail: 'Phase and Question combination already exists!',
								life: 5000
							});
							break;
						} else {
							this.sectionExistsThree = false;
							if (this.seqDataThreeList[iterator] !== undefined && this.seqDataThreeList[iterator].get(phaseAssessmentQuestion.sectionId) === phaseAssessmentQuestion.sortSequence) {
								this.seqExistsThree = true;
								this.messageService.add({
									key: 'ppapSequenceToast',
									severity: 'error',
									summary: 'Error',
									detail: 'Phase and Sort Sequence combination already exists!',
									life: 5000
								});
								break;
							} else {
								this.seqExistsThree = false;
							}
						}
					}
				}
				if (!this.phaseSectionDuplicate && !this.sectionExistsZero && !this.sectionExistsOne && !this.sectionExistsTwo && !this.sectionExistsThree
					&& !this.seqExistsZero && !this.seqExistsOne && !this.seqExistsTwo && !this.seqExistsThree) {
					if (phaseAssessmentQuestion.isActive || phaseAssessmentQuestion.isActive === 'Yes') {
						phaseAssessmentQuestion.isActive = 'Yes';
					} else {
						phaseAssessmentQuestion.isActive = 'No';
					}
					if (phaseAssessmentQuestion.isMandatory || phaseAssessmentQuestion.isMandatory === 'Yes') {
						phaseAssessmentQuestion.isMandatory = 'Yes';
					} else {
						phaseAssessmentQuestion.isMandatory = 'No';
					}
					if (phaseAssessmentQuestion.questionDesc.length !== 0 && phaseAssessmentQuestion.sortSequence > 0 &&
						phaseAssessmentQuestion.phase !== undefined && phaseAssessmentQuestion.sectionName !== undefined) {
						this.loading = true;
						this.ppapPhaseQuestionService.updateData(phaseAssessmentQuestion).subscribe(resp => {
							this.loading = false;
							delete this.clonedQuestion[phaseAssessmentQuestion.questionId];
							this.getAllData();
						}, error => {
							this.loading = false;
						});
					} else {
						this.messageService.add({
							key: 'ppapSectionToast',
							severity: 'error',
							summary: 'Error',
							detail: 'Please Fill the Required Fields!',
							life: 5000
						});
					}
				} else {
					this.getAllData();
				}
			} else {
				this.getAllData();
			}
		}
	}
}
