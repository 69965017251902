/**This file contains a constant object that has properties that are necessary
 * for the build found in the `development` configuration in `angular.json`.
 */

//TODO add the client and resource id and check with api end point
export const environment = {
	envName: 'dev',
	production: false,
	tokenUrl: 'https://corpqa.sts.ford.com/adfs/oauth2/authorize',
	clientId: 'urn:epsw_prepord:clientid:web_epsw_preprod:dev',
	resource: 'urn:epsw_prepord:resource:web_epsw_preprod:dev',
	apiUrl: 'https://api.d01e.gcp.ford.com/epsw/api'
	// Note: To use vanity url, we need to deploy apps in both instance
	// apiUrl: 'https://wwwdev.api.epsw.ford.com/api/v1/psw'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error';  // Included with Angular CLI.
