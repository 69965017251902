import { Tier2SubSupplier } from '../Tier2SubSupplier';
import { PswPartDetail } from '../psw-part-detail';
import {PpapPackageParts} from '../ppap-package-parts';

export class PswResponse {
	programCode :number;
	siteCode :string[];
	mapOfStringLong :any;
	mapOfStringList:Map<string, any>;
	genericString: string;
	ppapPackageIdentifierEntity: any;
	familyPswAnnexureOptionalHeader: any;
	genericInt: number;
	listOfPpapPackageParts: any[];
	genericBoolean :boolean;
	tier2SubSuppliers: Tier2SubSupplier[];
	familyPswAnnexDetailsEntity:any;
	mapOfStringListMap: Map<string, any[]>;
	pswPartDetailEntity: any;
	ppapPackageParts: PpapPackageParts;
	ppapPackagePartsEntity:any;
	interimPpapStatus: string;

	constructor(pswResponse :PswResponse) {
		Object.assign(this, pswResponse);
	}
}
