import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {PswPartDetail} from '../models/psw-part-detail';
import {Config} from '../config';
import {HttpClient, HttpParams} from '@angular/common/http';
import {PpapPackageParts} from '../models/ppap-package-parts';
import {FamilyPswAnnexureOptionalHeader} from '../models/FamilyPswAnnexureOptionalHeader';
import {PswResponse} from '../models/response/PswResponse';

@Injectable({
	providedIn: 'root'
})
export class SupplierViewService {
	private config = new Config();
	private httpUrlPpapPackageSave = `${this.config.url}/epsw/supplierView/savePswPartData`;
	private httpUrlPpapPackagePartsSave = `${this.config.url}/epsw/supplierView/savePhaseValueData`;
	private httpUrlPswFormRetrieve = `${this.config.url}/epsw/supplierView/retrievePsw`;
	private httpUrlPartsRetrieve = `${this.config.url}/epsw/supplierView/retrievePartsData`;
	private httpUrlGetAllPartsData = `${this.config.url}/epsw/supplierView/getAllData`;
	private httpUrlGetCmmsData = `${this.config.url}/epsw/supplierView/getCmmsData`;
	private httpUrlCopyFromData = `${this.config.url}/epsw/supplierView/getCopyFromPrefixBaseSuffix`;
	private httpUrlCopyToData = `${this.config.url}/epsw/supplierView/getCopyToPrefixBaseSuffix`;
	private httpUrlCopyFromToData = `${this.config.url}/epsw/supplierView/getCopyFromToData`;
	private httpUrlGetAllData = `${this.config.url}/epsw/supplierView/getAll`;
	// private httpUrlPswPartsData = `${this.config.url}/epsw/supplierView/getPswPartsData`;
	// private httpUrlSupplierRetrieve = `${this.config.url}/epsw/supplierView/retrieveData`;
	private httpUrlGetGcpMcpvVolumeData = `${this.config.url}/epsw/supplierView/getGcpMcpvVolumeData`;
	private httpUrlGetGcpMcpvCapacityData = `${this.config.url}/epsw/supplierView/getGcpMcpvCapacityData`;
	private httpUrlPpapPackageGet = `${this.config.url}/epsw/supplierView/getPswPartData`;
	private httpUrlGetElementResponseStatus = `${this.config.url}/epsw/supplierView/getElementResponseStatus`;
	//Added for US3853075
	private httpUrlGetAllPartList = `${this.config.url}/epsw/supplierView/getAllPartListData`;
	private httpUrlPpapPackageSaveAll = `${this.config.url}/epsw/supplierView/saveAllPswPartData`;
	private httpUrlPswFormRetrieveFamilyParts = `${this.config.url}/epsw/supplierView/retrieveFamilyPsw`;
	private httpUrlDeletePswPart = `${this.config.url}/epsw/supplierView/deletePswPart`;
	private httpUrlSaveFamilyPswPart = `${this.config.url}/epsw/supplierView/savePswFamilyParts`;
	private httpUrlSaveFamilyAnnexPsw = `${this.config.url}/epsw/supplierView/savePswFamilyAnnex`;
	private httpUrlAnnexureExcelDownload = `${this.config.url}/epsw/supplierView/annexureExcelDownload`;
	private httpUrlSaveFamilyAnnexPswDetails = `${this.config.url}/epsw/supplierView/savePswFamilyAnnexDetailsData`;
	private httpUrlFamilyAnnexExcelUpload = `${this.config.url}/epsw/supplierView/familyAnnexExcelUpload`;
	private httpUrlPswFormRetrieveFamilyAnnexDetails = `${this.config.url}/epsw/supplierView/retrieveFamilyAnnexPswDetails`;
	private httpUrlSaveAllFamilyAnnexDetails = `${this.config.url}/epsw/supplierView/saveAllFamilyAnnexDetails`;
	private httpUrlUpdateFamilyDetails = `${this.config.url}/epsw/supplierView/updateFamilyAnnexDetails`;
	private httpUrlPswFormGetAllFamilyAnnexDetails = `${this.config.url}/epsw/supplierView/getAllFamilyAnnexPswDetails`;
	private httpUrlPswFormGetAllPswPartDetails = `${this.config.url}/epsw/supplierView/getAllPswPartDetailData`;
	private httpUrlPswFormGetAllMinCreatedByData = `${this.config.url}/epsw/supplierView/getAllMinCreatedByData`;
	private httpUrlPswFormGetAllPswData = `${this.config.url}/epsw/supplierView/getAllPswData`;
	private httpUrlPswFormfetchFamilyPSWAnnexureOptionalHeaderData = `${this.config.url}/epsw/supplierView/fetchFamilyPSWAnnexureOptionalHeaderData`;
	private httpUrlPswFormUpdateFamilyPSWAnnexureOptionalHeaderData = `${this.config.url}/epsw/supplierView/updateFamilyPSWAnnexureOptionalHeaderData`;
	private httpUrlPpapPackageUpdateInterimPpapStatus =  `${this.config.url}/epsw/supplierView/updateInterimPpapStatus`;
	constructor(private http: HttpClient) {
	}

	saveData(pswPartDetail : PswPartDetail, ppapPackagePartsEntityId: number): Observable<any> {
		console.log('PSW Part Detail Save Call');
		return this.http.post<any>(this.httpUrlPpapPackageSave+'/'+ppapPackagePartsEntityId, pswPartDetail,{responseType:'json'});
	}

	getRetrievalData(ppapPackagePartsEntityId: number) {
		return this.http.get<PswResponse>(this.httpUrlPswFormRetrieve+'/'+ppapPackagePartsEntityId);
	}

	getPartsRetrievalData(ppapPackagePartsEntityId: number) {
		return this.http.get<PswResponse>(this.httpUrlPartsRetrieve+'/'+ppapPackagePartsEntityId);
	}

	getAllData(entityID): Observable<any>{
		const params = new HttpParams().set('entityID', entityID);
		return this.http.get<any>(this.httpUrlGetAllPartsData,{params});
	}

	getCmmsData(noPartPrefix,noPartBase,noPartSuffix,programCode,siteCode): Observable<any>{
		const params = new HttpParams().set('noPartPrefix', noPartPrefix).set('noPartBase',noPartBase).set('noPartSuffix',noPartSuffix)
			.set('programCode',programCode).set('siteCode',siteCode);
		return this.http.get<any>(this.httpUrlGetCmmsData,{params});
	}

	getAll(): Observable<any>{
		//const params = new HttpParams().set('entityID', entityID);
		return this.http.get<any>(this.httpUrlGetAllData+'/');
	}

	getCopyFromPrefixBaseSuffix(ppapPackageIdentifierId): Observable<any>  {
		const params = new HttpParams().set('ppapPackageIdentifierId', ppapPackageIdentifierId);
		return this.http.get<any[]>(this.httpUrlCopyFromData,{params,responseType:'json'});
	}

	getCopyToPrefixBaseSuffix(ppapPackageIdentifierID, copyFromPartNumbers) {
		const params = new HttpParams().set('ppapPackageIdentifierID', ppapPackageIdentifierID).set('copyFromPartNumbers',copyFromPartNumbers)
		return this.http.get<any[]>(this.httpUrlCopyToData,{params,responseType:'json'});
	}
	// copyData(copyFromPartNumbers, partNumbers) {
	// 	const params = new HttpParams().set('copyFromPartNumbers', copyFromPartNumbers).set('partNumbers',partNumbers)
	// 	return this.http.get<any[]>(this.httpUrlCopyFromToData+'/',{params,responseType:'json'});
	// }

	copyData(copyFromPartNumbers, partNumbers,siteCode,ppapPackageIdentifierID): Observable<any> {
		console.log('Inside updateSchedule');
		const params = new HttpParams().set('siteCode', siteCode).set('ppapPackageIdentifierID',ppapPackageIdentifierID)
		return this.http.post<any>(this.httpUrlCopyFromToData + '/' + copyFromPartNumbers , partNumbers,
			{params,responseType:'json'});
	}

	// getPartDetailData(pswPartDetailID: number) {
	// 	return this.http.get<any>(this.httpUrlPswPartsData+'/'+pswPartDetailID);
	// }
	// getEntityDataOfSelectedRow(packageIdentifier: any): Observable<any> {
	// 	return this.http.get<any>(this.httpUrlSupplierRetrieve+'/'+packageIdentifier);
	// }
	getGcpMcpvVolumeData(siteCode, noPartPrefix: any, noPartBase: any, noPartSuffix: any) {
			const params = new HttpParams().set('siteCode', siteCode).set('noPartPrefix',noPartPrefix)
				.set('noPartBase',noPartBase).set('noPartSuffix',noPartSuffix);
			return this.http.get<any[]>(this.httpUrlGetGcpMcpvVolumeData,{params});
		}

	getGcpMcpvCapacityData(siteCode, noPartPrefix: any, noPartBase: any, noPartSuffix: any) {
		const params = new HttpParams().set('siteCode', siteCode).set('noPartPrefix',noPartPrefix)
			.set('noPartBase',noPartBase).set('noPartSuffix',noPartSuffix);
		return this.http.get<any[]>(this.httpUrlGetGcpMcpvCapacityData,{params});
	}
	getData(ppapPackagePartsEntityId: number) {
		console.log('PSW Part Detail get Call');
		return this.http.get<PswResponse>(this.httpUrlPpapPackageGet+'/'+ppapPackagePartsEntityId,{responseType:'json'});
	}

	getStaPdStatusFromAssessmentTable(ppapPackageIdentifierId: any) {
		return this.http.get<any>(this.httpUrlGetElementResponseStatus+'/'+ppapPackageIdentifierId,{responseType:'json'});
	}

	savePhaseValueData(phaseValue: PpapPackageParts, ppapPackagePartsEntityId: number) {
		return this.http.put<any>(this.httpUrlPpapPackagePartsSave+'/'+ppapPackagePartsEntityId, phaseValue,{responseType:'json'});
	}
	//Added for US3853075
	getAllPartListData(entityIdList: string) {
		const params = new HttpParams().set('entityIdList',entityIdList);
		return this.http.get<any[]>(this.httpUrlGetAllPartList,{params,responseType: 'json'});
	}

	saveAllData(pswPartDetailSave: PswPartDetail, entityIdList: string,mode : string) {
		console.log('PSW Part Detail Save Call');
		const params = new HttpParams().set('entityIdList', entityIdList).set('mode',mode);
		return this.http.post<any>(this.httpUrlPpapPackageSaveAll+'/', pswPartDetailSave,{responseType:'json',params});
	}

	getFamilyRetrievalData(familyPswId: number) {
		return this.http.get<any>(this.httpUrlPswFormRetrieveFamilyParts+'/'+familyPswId);
	}

	deletePswPart(packageId: number) {
		return this.http.delete<any>(this.httpUrlDeletePswPart + '/'+packageId, {responseType: 'json'});
	}

	savePswFamilyParts(prefixBaseSuffix: any,entityId: any) {
		return this.http.post<any>(this.httpUrlSaveFamilyPswPart + '/' + prefixBaseSuffix , entityId,
			{responseType:'json'});
	}
	//Added for US4027839
	saveFamilyAnnex(prefixBaseSuffix, entityId: number,ppapPartsArray: PpapPackageParts[]) {
		// const params = new HttpParams().set('prefixBaseSuffix', prefixBaseSuffix);
		return this.http.post<any>(this.httpUrlSaveFamilyAnnexPsw + '/' +  prefixBaseSuffix, ppapPartsArray,
			{responseType:'json'});
	}

	downloadFamilyPSWAnnexureExcel(familyPswId) {
		// const params = new HttpParams().set('prefixBaseSuffix', prefixBaseSuffix);
		this.http.get(this.httpUrlAnnexureExcelDownload + '/' + familyPswId,
			{responseType:'blob'}).subscribe((response) => {
			this.downLoadFile(response, 'text/csv');
		});
	}

	private downLoadFile(data: any, type: string) {
		const blob = new Blob([data], {type});
		const url = window.URL.createObjectURL(blob);
		const anchor = document.createElement('a');
		anchor.download = 'ePSW Family Annexure Details.xlsm';
		anchor.href = url;
		anchor.click();
	}

	saveFamilyAnnexDetails(familyPswId) {
		return this.http.post<any>(this.httpUrlSaveFamilyAnnexPswDetails + '/' +  familyPswId,
			{responseType:'json'});
	}

	uploadFamilyAnnexureExcel(uploadFamilyAnnexureExcel: FormData) {
		return this.http.post(this.httpUrlFamilyAnnexExcelUpload, uploadFamilyAnnexureExcel);
	}

	getFamilyAnnexDetails(familyPswId: number) {
		return this.http.get<any>(this.httpUrlPswFormRetrieveFamilyAnnexDetails+'/'+familyPswId);
	}

	saveAllFamilyAnnexDetails(entityIdList: string) {
		return this.http.post<any>(this.httpUrlSaveAllFamilyAnnexDetails + '/' +  entityIdList,
			{responseType:'json'});
	}

	updateData(rowData: PpapPackageParts) {
		return this.http.put<PswResponse>(this.httpUrlUpdateFamilyDetails+'/'+rowData.ppapPackagePartsEntityID, rowData);
	}

	getAllFamilyAnnexPswDetails(ppapPackageIdentifierId) {
		const params = new HttpParams().set('ppapPackageIdentifierId', ppapPackageIdentifierId);
		return this.http.get<any>(this.httpUrlPswFormGetAllFamilyAnnexDetails,{params});
	}

	getAllPswPartDetailData() {
		return this.http.get<any>(this.httpUrlPswFormGetAllPswPartDetails);
	}

	getAllMinCreatedByData(ppapPackageIdentifierId) {
		const params = new HttpParams().set('ppapPackageIdentifierId', ppapPackageIdentifierId);
		return this.http.get<any>(this.httpUrlPswFormGetAllMinCreatedByData,{params});
	}

	getAllPswData(ppapPackageIdentifierId) {
		const params = new HttpParams().set('ppapPackageIdentifierId', ppapPackageIdentifierId);
		return this.http.get<any>(this.httpUrlPswFormGetAllPswData,{params});
	}

	fetchFamilyPSWAnnexureOptionalHeader(ppapPackageIdentifierId) {
		const params = new HttpParams().set('ppapPackageIdentifierId', ppapPackageIdentifierId);
		return this.http.get<PswResponse>(this.httpUrlPswFormfetchFamilyPSWAnnexureOptionalHeaderData,{params});
	}

	// updateFamilyPSWAnnexureOptionalHeader(familyPswAnnexureOptionalHeader: FamilyPswAnnexureOptionalHeader){
	// 	return this.http.post<any>(this.httpUrlPswFormUpdateFamilyPSWAnnexureOptionalHeaderData+'/'+familyPswAnnexureOptionalHeader,
	// 	{responseType:'json'});
	// }

	updateFamilyPSWAnnexureOptionalHeader(pswPartDetailSave: FamilyPswAnnexureOptionalHeader) {
		console.log('PSW Part Detail Save Call');
		return this.http.post<any>(this.httpUrlPswFormUpdateFamilyPSWAnnexureOptionalHeaderData+'/', pswPartDetailSave,{responseType:'json'});
	}

	updateInterimPpapStatus(ppapPackageIdentifierId): Observable<any>  {
		return this.http.post<any>(this.httpUrlPpapPackageUpdateInterimPpapStatus+'/'+ppapPackageIdentifierId,{responseType:'json'});
	}
}
