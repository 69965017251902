import {Component, OnInit} from '@angular/core';
import {ConfirmationService, MenuItem, Message, SelectItem} from 'primeng/api';
import {auditLayout, DropdownOption} from '../models/dropdown-option';
import {ActivatedRoute, Router} from '@angular/router';
import {PswPartDetail} from '../models/psw-part-detail';
import {DigitalPpapPackageService} from '../services/digital-ppap-package.service';
import {PpapPackageIdentifier} from '../models/ppap-package-identifier';
import {EpswHomepageService} from '../services/epsw-homepage.service';
import {SupplierViewService} from '../services/supplier-view.service';
import {MessageService} from 'primeng';
import {RoleBasedAccessProviderService} from '../psw-approval-flow/role-based-access-provider.service';
import {PpapPackageParts} from '../models/ppap-package-parts';
import {PpapElementRecords} from '../models/ppap-element-records';

interface PartNumberDetail {
	partnumber: string;
	partdescription: string;
	lastUpdatedBy: string;
	lastUpdatedOn: string;
	ppapApprovalStatus: string;
}

@Component({
	selector: 'supplier-view',
	templateUrl: './supplier-view.component.html',
	styleUrls: ['./supplier-view.component.scss'],
	providers: [ConfirmationService]
})

export class SupplierViewComponent implements OnInit {
	styles =
		{
			partnumber: '150px',
			partdescription: '250px',
			ppapApprovalStatus: '150px',
			lastUpdatedBy: '100px',
			lastUpdatedOn: '150px'
		};
	checked = false;
	data: any;
	imageSource: any;
	index: 4;
	isLoading = false;
	isShown: boolean;
	// table
	partNumberDetail: PpapPackageParts[] = [];
	cols: any[];
	ispartInformation: boolean;
	//selectedPartNumber: any;
	filteredPartNumber: any;
	auditLayout: DropdownOption[] = auditLayout;
	isVisible: boolean;
	isOrganizationInfo: boolean;
	isInteriumStatus: boolean;
	isViewMode: boolean;
	msgs: Message[] = [];
	isEpswInformation: boolean;
	isCopyVisible: boolean;
	//copyToPartNumbersOption: DropdownOption[] = copyToPartNumbersOption;
	//copyToPartNumbers: DropdownOption[];
	partNumbersOption: SelectItem[];
	partNumbers: any[]=[];
	display: boolean;
	//Breadcrumb
	menuItems: MenuItem[];
	home: MenuItem;
	pswPartDetailSave: PswPartDetail[];
	pswPartDetail: PswPartDetail;
	ppapPackageIdentifierId: any;
	ppapPackageIdentifier: PpapPackageIdentifier;
	supplierName: string;
	//private ppapPackagePartsEntityId: any;
	nextAuditPlan: any;
	isImageShown: any;
	isFormCompleted: boolean;
	addDataForParts: any = {};
	private copyToList: any[] = [];
	private copyFromList: any;
	copyFromPartNumbersOption: SelectItem[];
	copyFromPartNumbers: DropdownOption;
	isCopyToDataVisible: boolean;
	isCopyButtonVisible: boolean;
	private copyFromData: any[];
	private copyFromDataList: SelectItem[];
	private copyFromDataResp: any;
	private entityID: any;
	private pswPartDetailID: any;
	private label: any;
	private value: any;
	private copyFromPartNumbersList: any[] = [];
	private newList: any;
	apsAttributes: any;
	userRole: any;
	enableOnViewMode: boolean;
	pswAccessControl: Map<string, any> = new Map<string, any>();
	pswApproverAccessControl: Map<string, any> = new Map<string, any>();
	// imageNotUploaded: boolean = false;
	cdsidNotSaved = false;
	cdsidSavedFlag=false;
	requiredElementsNotSaved = false;
	requiredElementsSavedFlag=false;
	mode: any;
	tier2Saved = false;
	tier2SavedFlag=false;
	displaySubmitParts=false;
	commodityImgSaved = false;
	commodityImgSavedFlag = false;
	disableSubmit: boolean;
	ppapAssessmentResponse: PpapElementRecords[] = [];
	ppapLevelParts: string;
	status: any;
	isAuditLayoutVisible = false;
	hideSubmit = true;
	pswAccessControlForSupplier: Map<string, any> = new Map<string, any>();
	isSubmitted = false;
	chartRequiredElementsFlag: boolean;
	phaseType: string;
	private displayMessage: Message[] = [];
	//Alert message - US3738472
	message: any;
	ppapPackagePartsList: any[] = [];
	private newPartList: any[] = [];
	//Added for US3853075
	enableCheckBox = false;
	familyPswParts = false;
	isShownFamilyPsw = false;
	familyParts: any;
	private showLoadPsw = true;
	private checkedEntityIdList: any[]=[];
	isShowFamilCheckbox: boolean;
	partCount: number;
	familyId: any;
	selectedParts: any[]=[];
	displayPpapPhase: any;
	displayLoadViewPSW = false;
	displayLoadViewFamilyPSW = false;
	recallComments = '';
	enableRecallSection = false;
	recallSubmission = false;
	pdStaApprovalRequiredL1=false;
	enableOnViewModeForStaApprovalRequired = true;
	cmmsApprovalMessage: string;
	cmmsNotApprovedParts: any[] = [];
	screen2ErrorMsg: any[] = [];
	screen3ErrorMsg: any[] = [];
	displayNotCmmsPartDlg = false;
	isPresentInPartDetial:boolean;
	constructor(private confirmationService: ConfirmationService, private epswHomepageService: EpswHomepageService,
				private digitalService: DigitalPpapPackageService, private messageService: MessageService,
				private router: Router, private route: ActivatedRoute, private homePageService: EpswHomepageService,
				private supplierViewService: SupplierViewService, private digitalPpapPackageService: DigitalPpapPackageService) {
		this.ppapPackageIdentifierId = this.route.snapshot.paramMap.get('id');
		this.mode = this.route.snapshot.paramMap.get('mode');
		this.phaseType = sessionStorage.getItem('ppapPhase');
		this.displayPpapPhase = sessionStorage.getItem('displayPpapPhase');
		// this.mode = this.router.getCurrentNavigation().extras.state.mode;
		this.isShown = false;
		this.isCopyToDataVisible = false;
		this.isCopyButtonVisible = false;
		//this.isAuditLayoutVisible = false;
		if (sessionStorage.getItem('attributes') !== null) {
			this.apsAttributes = sessionStorage.getItem('attributes');
			this.userRole = JSON.parse(this.apsAttributes).userRole;
			this.pswAccessControlForSupplier= RoleBasedAccessProviderService.getAccessForSupplierRole(this.userRole);
			if (this.userRole === 'STASiteEngineer' || this.userRole === 'APQPSiteSTAEngineer'
				|| this.userRole === 'STASiteSupervisor' || this.userRole === 'STASiteManager') {
				if(this.userRole === 'STASiteEngineer'){
					this.pswApproverAccessControl = RoleBasedAccessProviderService.getPhaseBasedAccessForApprover('STASiteEngineer', true)
				} else if(this.userRole === 'STASiteSupervisor'){
					this.pswApproverAccessControl = RoleBasedAccessProviderService.getPhaseBasedAccessForApprover('STASiteSupervisor', true)
				} else if(this.userRole === 'STASiteManager'){
					this.pswApproverAccessControl = RoleBasedAccessProviderService.getPhaseBasedAccessForApprover('STASiteManager', true)
				} else {
					this.pswApproverAccessControl = RoleBasedAccessProviderService.getPhaseBasedAccessForApprover('APQPSiteSTAEngineer', true)
				}

			} else if (this.userRole === 'PDEngineer') {
				this.pswApproverAccessControl = RoleBasedAccessProviderService.getPhaseBasedAccessForApprover('PDEngineer', true)
			} else {
				this.pswApproverAccessControl = RoleBasedAccessProviderService.getPhaseBasedAccessForApprover(this.userRole, false)
			}
		}
		//this.isImageShown = false;
		//this.ppapPackagePartsEntityId = this.router.getCurrentNavigation().extras.state.PpapPackagePartsId;
		this.partNumberDetail = [];
		this.ppapPackageIdentifier = new PpapPackageIdentifier(this.ppapPackageIdentifier);
		// editable - If the Package is rejected/needs clarification
		// this.getAllPswPartsFormData();
		this.getAllPartsDetails();
		this.getAllIdentifierDetails();
		this.getCopyFromData();
		console.log(this.partNumberDetail)
		if (this.mode === 'view') {
			this.enableOnViewMode = false;
		} else {
			this.enableOnViewMode = true;
		}
		this.display = false;
		this.isViewMode = true;
		this.isVisible = true;
		this.ispartInformation = true;
		this.isEpswInformation = true;
		this.isCopyVisible = true;

		// this.partNumbersOption = [
		// 	{
		// 		label: 'AB39 – 2122345 – AE',
		// 		value: null
		// 	},
		// 	{
		// 		label: 'AB39 – 2122346 – AE',
		// 		value: {id: 1, name: 'Part1', code: 'Part1'}
		// 	},
		// 	{
		// 		label: 'AB39 – 2122347 – AE',
		// 		value: {id: 1, name: 'Part2', code: 'Part2'}
		// 	},
		// 	{
		// 		label: 'AB39 – 2122348 – AE',
		// 		value: {id: 1, name: 'Part2', code: 'Part2'}
		// 	},
		// 	{
		// 		label: 'AB39 – 2122349 – AE',
		// 		value: {id: 1, name: 'Part2', code: 'Part2'}
		// 	}
		// ];
		//Added for completed flag
		if (this.ppapLevelParts !== '1') {
			this.digitalPpapPackageService.checkIfTier2PPAPPackageSaved(this.ppapPackageIdentifierId).subscribe(tier2Response => {
				console.log('tier2Response'+tier2Response);
				if (tier2Response === 0) {
					this.tier2Saved = true;
				}
				else{
					this.tier2SavedFlag=true;
				}
			});
			this.epswHomepageService.retrieveCommodityImage(this.ppapPackageIdentifierId).subscribe(retrieveResp => {
				if (retrieveResp !== null && retrieveResp['commodityImg'] !== null || undefined) {
					this.commodityImgSavedFlag =true;
				}else{
					this.commodityImgSaved =true;
				}
			});
		}
		this.digitalPpapPackageService.checkIfPdStaCdsidSaved(this.ppapPackageIdentifierId).subscribe(response => {
			// if (commodityResponse['PpapPackageCommodityImage'].length === 0) {
			// 	this.imageNotUploaded = true;
			// 	// this.messageService.add({severity: 'error', summary: 'Please Upload Commodity Image in PPAP Package', life: 5000});
			// }
			const commodityResponse = response.mapOfStringList;
			commodityResponse['PpapPackageIdentifierEntity'].pdApprovalRequired = commodityResponse['PpapPackageIdentifierEntity']
				.pdApprovalRequired === '1'?'true':'false';
			if (commodityResponse['PpapPackageIdentifierEntity'].pdApprovalRequired === 'true') {
				if (commodityResponse['PpapPackageIdentifierEntity'].length === 0 || commodityResponse['PpapPackageIdentifierEntity'].length === 1) {
					this.cdsidNotSaved = true;
					this.cdsidSavedFlag = false;
					// this.messageService.add({severity: 'error', summary: 'Please Provide CDSID for PD and STA in PPAP Package', life: 5000});
				}
				else{
					this.cdsidSavedFlag = true;
				}
			} else {
				if (commodityResponse['PpapPackageIdentifierEntity'].length === 0) {
					this.cdsidNotSaved = true;
					this.cdsidSavedFlag = false;
					// this.messageService.add({severity: 'error', summary: 'Please Provide CDSID for STA in PPAP Package', life: 5000});
				}
				else{
					this.cdsidSavedFlag = true;
				}
			}
			console.log('PPAP Level  ' + this.ppapLevelParts);
			if ((this.ppapLevelParts !== '1'|| this.ppapLevelParts === '1') && this.chartRequiredElementsFlag !== true) {
				this.digitalPpapPackageService.getPpapElementRequired(this.ppapPackageIdentifierId).subscribe(repo => {
					console.log('Ppap Required Check Data for Sumbit: ' + repo);
					if (repo.length > 0) {
						this.digitalPpapPackageService.getPackagePpapElementSubmitCheck(this.ppapPackageIdentifierId).subscribe(repos => {
								console.log('Ppap Required Response Check Data for Submit: ' + repos);
								if (repos.length >= 1) {
									this.requiredElementsSavedFlag=false;
								}
								else{
									this.requiredElementsSavedFlag=true;
								}

							}, error => {
								console.log('Error Log ' + error);
							}
						);
					} else if (repo.length <= 0) {
						this.requiredElementsNotSaved = true;
						// this.messageService.add({severity: 'error',
						// summary: 'Please Complete PPAP Required Element and Response in PPAP Package', life: 5000});
					}
				}, error => {
					console.log('Error Log ' + error);
				});
			}
		});
		this.epswHomepageService.getDataOfSelectedRow(this.ppapPackageIdentifierId).subscribe(async resp1 => {
			const ppapPackageIdentifierDisplayOne = resp1.ppapPackageIdentifierEntity;
			const ppapPartsArray = ppapPackageIdentifierDisplayOne?.ppapPackagePartsEntity;
			console.log(ppapPartsArray[0].noPartBase);
			if (ppapPartsArray[0].noPartBase === '1508') {
				this.requiredElementsSavedFlag = true;
			}
		});
	}

	ngOnInit(): void {
		//table
		this.cols = [
			{field: 'noPartPrefix-noPartBase-noPartSuffix', header: 'PARTS[PREFIX-BASE-SUFFIX]'},
			{field: 'partDescription', header: 'PART DESCRIPTION'},
			{field: 'status', header: 'PPAP APPROVAL STATUS'},
			{field: 'lastUpdatedBy', header: 'LAST UPDATED BY'},
			{field: 'lastUpdatedOn', header: 'LAST UPDATED ON'},
		];

		// this.getSupplierName();
		// this.partNumberDetail = [
		// 	{
		// 		partnumber: 'AB39 – 2122345 – AE',
		// 		partdescription: 'HGA ASY FRT DT HGA ASY FRT DT HGA ASY FRT DT HGA ASY FRT DT',
		// 		ppapApprovalStatus: 'APPROVED',
		// 		lastUpdatedBy: 'User 1',
		// 		lastUpdatedOn: '08-Jul-2021 4:38PM'
		// 	},
		// 	{
		//
		// 		partnumber: 'AB39 – 2122346 – AE',
		// 		partdescription: 'HGA ASY FRT DT',
		// 		ppapApprovalStatus: 'APPROVED',
		// 		lastUpdatedBy: 'User 2',
		// 		lastUpdatedOn: '08-Jul-2021 4:40PM'
		// 	},
		// 	{
		//
		// 		partnumber: 'AB39 – 2122347 – AE',
		// 		partdescription: 'HGA ASY FRT DT',
		// 		ppapApprovalStatus: 'NEEDS CLARIFICATION',
		// 		lastUpdatedBy: 'User 3',
		// 		lastUpdatedOn: '08-Jul-2021 5:00PM'
		// 	},
		// 	{
		//
		// 		partnumber: 'AB39 – 2122348 – AE',
		// 		partdescription: 'HGA ASY FRT DT',
		// 		ppapApprovalStatus: 'REJECTED',
		// 		lastUpdatedBy: 'User 4',
		// 		lastUpdatedOn: '08-Jul-2021 4:38PM'
		// 	},
		// 	{
		//
		// 		partnumber: 'AB39 – 2122349 – AE',
		// 		partdescription: 'HGA ASY FRT DT',
		// 		ppapApprovalStatus: 'REJECTED',
		// 		lastUpdatedBy: 'User 1',
		// 		lastUpdatedOn: '08-Jul-2021 4:38PM'
		// 	},
		// 	{
		//
		// 		partnumber: 'AB39 – 2122350 – AE',
		// 		partdescription: 'HGA ASY FRT DT',
		// 		ppapApprovalStatus: 'REJECTED',
		// 		lastUpdatedBy: 'User 2',
		// 		lastUpdatedOn: '08-Jul-2021 4:38PM'
		// 	},
		// 	{
		//
		// 		partnumber: 'AB39 – 2122351 – AE',
		// 		partdescription: 'HGA ASY FRT DT',
		// 		ppapApprovalStatus: 'INTERIM ACCEPTED',
		// 		lastUpdatedBy: 'User 3',
		// 		lastUpdatedOn: '08-Jul-2021 4:38PM'
		// 	},
		// 	{
		//
		// 		partnumber: 'AB39 – 2122352 – AE',
		// 		partdescription: 'HGA ASY FRT DT',
		// 		ppapApprovalStatus: 'INTERIM ACCEPTED',
		// 		lastUpdatedBy: 'User 4',
		// 		lastUpdatedOn: '08-Jul-2021 4:38PM'
		// 	},
		// 	{
		//
		// 		partnumber: 'AB39 – 2122353 – AE',
		// 		partdescription: 'HGA ASY FRT DT',
		// 		ppapApprovalStatus: 'INTERIM ACCEPTED',
		// 		lastUpdatedBy: 'User 1',
		// 		lastUpdatedOn: '08-Jul-2021 4:38PM'
		// 	},
		// 	{
		//
		// 		partnumber: 'AB39 – 2122354 – AE',
		// 		partdescription: 'HGA ASY FRT DT',
		// 		ppapApprovalStatus: 'APPROVED',
		// 		lastUpdatedBy: 'User 2',
		// 		lastUpdatedOn: '08-Jul-2021 4:38PM'
		// 	},
		// 	{
		//
		// 		partnumber: 'AB39 – 2122355 – AE',
		// 		partdescription: 'HGA ASY FRT DT',
		// 		ppapApprovalStatus: 'APPROVED',
		// 		lastUpdatedBy: 'User 3',
		// 		lastUpdatedOn: '11-Jul-2021 4:38AM'
		// 	},
		// 	{
		//
		// 		partnumber: 'AB39 – 2122356 – AE',
		// 		partdescription: 'HGA ASY FRT DT',
		// 		ppapApprovalStatus: 'APPROVED',
		// 		lastUpdatedBy: 'User 4',
		// 		lastUpdatedOn: '10-Jul-2021 7:38PM'
		// 	},
		// 	{
		//
		// 		partnumber: 'AB39 – 2122357 – AE',
		// 		partdescription: 'HGA ASY FRT DT',
		// 		ppapApprovalStatus: 'APPROVED',
		// 		lastUpdatedBy: 'User 1',
		// 		lastUpdatedOn: '09-Jul-2021 5:38PM'
		// 	}
		// ];
		//Breadcrumb
		this.home = {icon: 'fa fa-home'};
		this.menuItems = [
			{
				label: 'PPAP PACKAGE'
				// routerLink: '/DigitalPPAPPackageComponent'
			},
			{
				label: 'PSW HOME'
				// routerLink: '/ContinuedSupplierViewComponent',
			}
		];
		this.checkAndShowRejectSuccessMsg();
		this.checkAndShowApproveSuccessMsg();
		this.checkAndShowInterimSuccessMsg();
		this.checkAndShowNeedsClarificationSuccessMsg();
		this.checkAndShowSaveMsg();
		//Alert message - US3738472
		if (this.mode === 'edit' || this.mode === 'view') {
			this.homePageService.getAllData(this.ppapPackageIdentifierId).subscribe(partList => {
				this.ppapPackagePartsList = partList;
				if (this.ppapPackagePartsList.length >= 1) {
					this.message = 'The highlighted parts does not exists in cmms anymore.Please review and delete them in PPAP Package Screen.';
				} else {
					this.message = '';
				}
			});
		}
		this.homePageService.getAllData(this.ppapPackageIdentifierId).subscribe(partList => {
			this.newPartList = partList;
		});
		this.epswHomepageService.getAllPartsWoCmmsApproval(this.ppapPackageIdentifierId).subscribe(partList =>{
			if(this.displayPpapPhase !== 'Phase0'){
				let phaseValue;
				if (this.displayPpapPhase === 'Phase1'){
					phaseValue = 'Phase0';
				}else if(this.displayPpapPhase === 'Phase2'){
					phaseValue = 'Phase1';
				}else{
					phaseValue = 'Phase2';
				}
				this.cmmsApprovalMessage = 'One or more parts are not approved for '+phaseValue+
					' in VPP/MPP, please approve '+phaseValue+' in VPP/MPP';
				this.cmmsNotApprovedParts = partList;
			}
		});
	}

	checkAndShowRejectSuccessMsg() {
		let success = sessionStorage.getItem('rejectMessage');
		if (success) {
			success = JSON.parse(success);
			this.messageService.add({severity: 'success', summary: '', detail: success['message']});
			sessionStorage.removeItem('rejectMessage');
			setTimeout(() => {
				console.log('cleared');
				this.displayMessage = [];
			}, 2000);
		}
	}

	checkAndShowApproveSuccessMsg() {
		let success = sessionStorage.getItem('approveMessage');
		if (success) {
			success = JSON.parse(success);
			this.messageService.add({severity: 'success', summary: '', detail: success['message']});
			sessionStorage.removeItem('approveMessage');
			setTimeout(() => {
				console.log('cleared');
				this.displayMessage = [];
			}, 2000);
		}
	}

	checkAndShowInterimSuccessMsg() {
		let success = sessionStorage.getItem('interimAcceptedMessage');
		if (success) {
			success = JSON.parse(success);
			this.messageService.add({severity: 'success', summary: '', detail: success['message']});
			sessionStorage.removeItem('interimAcceptedMessage');
			setTimeout(() => {
				console.log('cleared');
				this.displayMessage = [];
			}, 2000);
		}
	}

	checkAndShowNeedsClarificationSuccessMsg() {
		let success = sessionStorage.getItem('needsClarificationMessage');
		if (success) {
			success = JSON.parse(success);
			this.messageService.add({severity: 'success', summary: '', detail: success['message']});
			sessionStorage.removeItem('needsClarificationMessage');
			setTimeout(() => {
				console.log('cleared');
				this.displayMessage = [];
			}, 2000);
		}
	}

	private checkAndShowSaveMsg() {
		let success = sessionStorage.getItem('saveMessage');
		if (success) {
			success = JSON.parse(success);
			this.messageService.add({severity: 'success', summary: '', detail: success['message']});
			sessionStorage.removeItem('saveMessage');
			setTimeout(() => {
				console.log('cleared');
				this.displayMessage = [];
			}, 3000);
		}
	}

	showDialog() {
		this.isShown = true;
		this.isVisible = false;
		this.isEpswInformation = false;
		this.isCopyVisible = false;
	}

	filterCountry($event: any) {
		this.filteredPartNumber = [
			{name: 'AB39-2122800-AE', code: 'Part1'},
			{name: 'AB39-2122801-AE', code: 'Part2'}];

	}

	onData() {
		this.isVisible = false;
		this.isShown = true;
	}

	onSave($event: MouseEvent) {
		if (this.isViewMode) {
			this.confirmationService.confirm({
				message: 'Are you sure you want to save the data?',
				header: 'Confirmation',
				icon: 'pi pi-info-circle',
				accept: () => {
					this.isVisible = true;
					this.isShown = false;
					this.isEpswInformation = true;
					this.isCopyVisible = true;
				},
				reject: () => {
				}
			});
		} else {
			this.isVisible = false;
			this.isShown = true;
		}
	}

	showConfirmationDialog($event: MouseEvent) {
		if (this.isViewMode) {
			this.confirmationService.confirm({
				message: 'Are you sure you want to cancel?',
				header: 'Confirmation',
				icon: 'pi pi-info-circle',
				accept: () => {
					this.isShown = false;
					this.isVisible = true;
					this.isEpswInformation = true;
					this.isCopyVisible = true;
					//this.router.navigate(['/home-page']);
				},
				reject: () => {
				}
			});
		} else {
			this.isShown = true;
			this.isVisible = false;
		}
	}

	showCopyData(copyFromPartNumbers: any) {
		//this.resetField();
		this.display = true;
		this.isLoading = false;
		this.getCopyFromData();
		this.isCopyToDataVisible = false;
		//this.getData(copyFromPartNumbers)
	}

	previousPage() {
		this.router.navigate(['/DigitalPPAPPackage/' + this.ppapPackageIdentifierId+'/'+'previous'+'/'+this.mode], {state: {mode: this.mode}});
	}

	homePage() {
		this.router.navigate(['/home-page/' + this.ppapPackageIdentifierId]);
	}

	retrieveData(selectedDataFromPartsTable) {
		//Added for US3853075
		if (selectedDataFromPartsTable.familyPswId !== null && selectedDataFromPartsTable.familyPswId !== undefined) {
			this.router.navigate(['/epsw-form/' + this.ppapPackageIdentifierId + '/' + this.mode + '/familyNew' + '/'
			+ selectedDataFromPartsTable.ppapPackagePartsEntityID + '/' +selectedDataFromPartsTable.familyPswId]);
		} else {
			this.router.navigate(['/epsw-form/' + this.ppapPackageIdentifierId + '/' + this.mode + '/new' + '/'
			+ selectedDataFromPartsTable.ppapPackagePartsEntityID]);
		}
	}

	retrieveFamilyData() {
		this.familyId = sessionStorage.getItem('familyPswId');
		if (this.partNumberDetail.length > 1) {
			for (const data of this.partNumberDetail) {
				// if(this.familyId !== null && this.familyId !== undefined){
				// 	this.router.navigate(['/epsw-form/' + this.ppapPackageIdentifierId + '/' + this.mode + '/familyNew' + '/'
				// 	+ this.familyId.toString()]);
				// } else {
				this.router.navigate(['/epsw-form/' + this.ppapPackageIdentifierId + '/' + this.mode + '/familyNew' + '/'
				+ data.ppapPackagePartsEntityID]);
				// }
			}
		}
		if (this.partNumberDetail.length === 1) {
			for (const data of this.partNumberDetail) {
				if(data.familyPswId !== null ){
					this.router.navigate(['/epsw-form/' + this.ppapPackageIdentifierId + '/' + this.mode + '/familyNew'+ '/'
					+ data.ppapPackagePartsEntityID]);
				} else {
				this.router.navigate(['/epsw-form/' + this.ppapPackageIdentifierId + '/' + this.mode + '/new' + '/'
				+ data.ppapPackagePartsEntityID]);
				}
			}
		}
	}

	getAllPartsDetails() {
		this.supplierViewService.getAllMinCreatedByData(this.ppapPackageIdentifierId).subscribe(
			respOne => {
				if(respOne.length >= 1){
					this.displayLoadViewPSW = true;
					this.displayLoadViewFamilyPSW = false;
				} else {
					this.displayLoadViewPSW = false;
					this.displayLoadViewFamilyPSW = true;
				}
			});
		this.digitalService.getAllData(this.ppapPackageIdentifierId).subscribe(resp => {
			//let partNumberDetailPre: PpapPackageParts[]=null;
			this.partNumberDetail = resp.listOfPpapPackageParts;
			this.isPresentInPartDetial=resp.genericBoolean;
			console.log('resp',resp.listOfPpapPackageParts,resp.genericBoolean);
			console.log('',this.partNumberDetail,this.isPresentInPartDetial);
			if (this.partNumberDetail.filter(i => i.noPartBase === '1508').length > 0){
				this.chartRequiredElementsFlag = true;
			}
			this.checkedEntityIdList = [];
			for (const data of this.partNumberDetail) {
				this.supplierViewService.getRetrievalData(data.ppapPackagePartsEntityID).subscribe(
					response => {
						const respOne = response.pswPartDetailEntity;
						for (const dataOne of this.partNumberDetail) {
							if (respOne.ppapPackagePartsEntityID === dataOne.ppapPackagePartsEntityID) {
								this.status = dataOne.status;
								// dataOne.nextAuditLayout = respOne.nextAuditPlan;
								dataOne.isFormCompleted = respOne.isFormCompleted;
								//data.nextAuditLayout = this.nextAuditPlan;
							}
							if (!this.pswApproverAccessControl.get('approverConditionEnabled')) {
								if (dataOne.status === 'REJECTED' || dataOne.status === 'NEEDS CLARIFICATION' || dataOne.status === 'INTERIM ACCEPTED') {
									this.hideSubmit = false;
									if(this.partNumberDetail[0].staApprovalRequired === 'true' && dataOne.status === 'REJECTED' || dataOne.status === 'NEEDS CLARIFICATION') {
										this.pdStaApprovalRequiredL1 = true;
										this.enableOnViewModeForStaApprovalRequired = false;
										console.log(this.pdStaApprovalRequiredL1)
									} else {
										this.pdStaApprovalRequiredL1 = false;
									}
									// break;
								}
							}
							console.log('Status '+dataOne.status);
							if(dataOne.status.includes('AWAITING APPROVAL') || dataOne.status.includes('LEVEL 1 SELF APPROVED')){
								this.enableRecallSection = true;
							} else {
								this.enableRecallSection = false;
							}
							console.log('Enable :  '+this.enableRecallSection);
							if (this.ppapLevelParts === '1' && dataOne.status === 'LEVEL 1 SELF APPROVED') {
								this.enableRecallSection = false;
							}
							this.partNumberDetail = this.partNumberDetail;
							for (const dataTwo of this.partNumberDetail) {
								if (dataTwo.isFormCompleted !== undefined && dataTwo.isFormCompleted !== null) {
									if (dataTwo.isFormCompleted === 'false') {
										this.disableSubmit = false;
										break;
									} else if (dataTwo.isFormCompleted === 'true') {
										this.disableSubmit = true;
									}
								} else {
									this.disableSubmit = false;
									break;
								}
							}
						}
						//Added for US3853075
						this.partCount = this.partNumberDetail.filter(e => e.familyPswId === null).length;
						if(this.partCount > 1){
							this.isShowFamilCheckbox = false;
						} else {
							this.isShowFamilCheckbox = true;
						}
					});
				// for(const data of this.partNumberDetail){
				// if (data.familyPswId === null) {
					this.checkedEntityIdList.push(data.ppapPackagePartsEntityID);
				// }
				sessionStorage.setItem('checkedEntityIdList', this.checkedEntityIdList.toString());
				// }
			}

			if(this.mode === 'view'){
				console.log(this.pdStaApprovalRequiredL1)
				this.pdStaApprovalRequiredL1 = this.partNumberDetail[0].staApprovalRequired === 'true';
				this.enableOnViewModeForStaApprovalRequired = false;
			}
		});
	}

	// getAllPswPartsFormData() {
	// 	// this.supplierViewService.getRetrievalData(this.ppapPackageIdentifierId).subscribe(respOne => {
	// 	// 	if (respOne.ppapPackagePartsEntityID !== null) {
	// 	// 		//this.nextAuditPlan = respOne.nextAuditPlan;
	// 	// 		this.partNumberDetail.push(respOne.nextAuditPlan);
	// 	// 		this.isFormCompleted = respOne.formCompleted;
	// 	// 		this.isFormCompletedCheck();
	// 	// 	}
	// 	// });
	// 	this.supplierViewService.getRetrievalData(this.ppapPackageIdentifierId).subscribe(respOne => {
	// 		if (respOne.ppapPackagePartsEntityID !== null) {
	// 			this.nextAuditPlan = respOne.nextAuditPlan;
	// 			this.isFormCompleted = respOne.isFormCompleted;
	// 			this.isFormCompletedCheck();
	// 		}
	// 	});
	// 	// this.supplierViewService.getAll().subscribe(resp => {
	// 	// 	this.pswPartDetailID = resp;
	// 	// 	// @ts-ignore
	// 	// 	//for (const data of this.pswPartDetail) {
	// 	// 	this.supplierViewService.getPartDetailData(this.pswPartDetailID).subscribe(respOne => {
	// 	// 		if (respOne.pswPartDetailID === this.pswPartDetailID) {
	// 	// 			//this.pswPartDetailSave = respOne;
	// 	// 			//this.entityID = respOne.ppapPackagePartsEntityID;
	// 	// 			this.partNumberDetail.push(respOne.nextAuditPlan);
	// 	// 			this.isFormCompleted = respOne.formCompleted;
	// 	// 			//this.partNumbersOption = respOne.custPartNumber;
	// 	// 			this.isFormCompletedCheck();
	// 	// 		}
	// 	// 	});
	// 	//}
	// 	//});
	// }

	getCopyFromData() {
		this.isCopyButtonVisible = false;
		this.copyFromPartNumbersOption = [];
		this.supplierViewService.getCopyFromPrefixBaseSuffix(this.ppapPackageIdentifierId).subscribe(
			value => {
				let iterator;
				this.copyFromList = value;
				for (iterator = 0; iterator < this.copyFromList.length; iterator++) {
					this.copyFromPartNumbersOption.push({
						label: this.copyFromList[iterator],
						value: this.copyFromList[iterator]
					});
				}
			});
	}

	getAllIdentifierDetails() {
		this.homePageService.getDataOfSelectedRow(this.ppapPackageIdentifierId).subscribe(resp => {
			this.ppapPackageIdentifier = resp.ppapPackageIdentifierEntity;
			this.ppapLevelParts = this.ppapPackageIdentifier?.ppapPackagePartsEntity[0].ppapLevel;
			console.log('PPAP Level : ' + this.ppapLevelParts);
			this.pswAccessControl = RoleBasedAccessProviderService.getPhaseBasedAccessForSupplier(this.userRole, this.phaseType);
			this.homePageService.getSupplierName(resp.ppapPackageIdentifierEntity.siteCode).subscribe(respOne => {
				console.log('Response for Supplier Name : ' + respOne.simsahSelectedSiteName);
				this.supplierName = respOne.simsahSelectedSiteName;
			});
			if (this.phaseType === 'Phase3') {
				this.isAuditLayoutVisible = false;
				// this.requiredElementsSavedFlag=true;
			} else {
				this.isAuditLayoutVisible = false;
			}
		});
	}

	private isFormCompletedCheck() {
		if (this.isFormCompleted === true) {
			this.isImageShown = true;
		} else {
			this.isImageShown = false;
		}
	}

	getCopyToParts(copyFromPartNumbers) {
		this.partNumbersOption = [];
		for (const data of copyFromPartNumbers) {
			this.copyFromPartNumbersList.push(data.value);
		}
		this.supplierViewService.getCopyToPrefixBaseSuffix(this.ppapPackageIdentifierId, this.copyFromPartNumbersList).subscribe(
			resp => {
				let i;
				this.copyToList = resp;
				this.partNumbersOption = [];
				// let arrlength;
				for (const data of copyFromPartNumbers) {
					for (const dataOne of this.copyToList) {
						if (data.value === dataOne)
							this.copyToList = this.copyToList.filter(val => !this.copyFromPartNumbersList.includes(val));
					}
				}
				for (i = 0; i < this.copyToList.length; i++) {
					this.partNumbersOption.push({label: this.copyToList[i], value: this.copyToList[i]});
				}
				this.partNumbers=[];
			});

	}

	getData(copyFromPartNumbers) {
		this.isCopyToDataVisible = true;
		this.isCopyButtonVisible = false;
		this.getCopyToParts(copyFromPartNumbers);
	}

	copyData(copyFromPartNumbers, partNumbers) {
		this.copyDataFromto(copyFromPartNumbers, partNumbers);
	}

	private copyDataFromto(copyFromPartNumbers, partNumbers) {
		this.isLoading = true;
		this.homePageService.getDataOfSelectedRow(this.ppapPackageIdentifierId).subscribe(resp => {
			this.ppapPackageIdentifier = resp.ppapPackageIdentifierEntity;
			this.supplierViewService.copyData(copyFromPartNumbers, partNumbers, resp.ppapPackageIdentifierEntity.siteCode,resp.ppapPackageIdentifierEntity.ppapPackageIdentifierID).subscribe(
				value => {
					this.pswPartDetailSave = value;
					for (const data of this.pswPartDetailSave) {
						this.supplierViewService.getRetrievalData(data.ppapPackagePartsEntityID).subscribe(
							response => {
								const respOne = response.pswPartDetailEntity;
								for (const dataOne of this.partNumberDetail) {
									//this.status = dataOne.status;
									if (respOne.ppapPackagePartsEntityID === dataOne.ppapPackagePartsEntityID) {
										// dataOne.nextAuditLayout = respOne.nextAuditPlan;
										dataOne.isFormCompleted = respOne.isFormCompleted;
										//data.nextAuditLayout = this.nextAuditPlan;
									}
									this.partNumberDetail = this.partNumberDetail;
									for (const dataTwo of this.partNumberDetail) {
										if (dataTwo.isFormCompleted !== undefined && dataTwo.isFormCompleted !== null) {
											if (dataTwo.isFormCompleted === 'false') {
												this.disableSubmit = false;
												break;
											} else if (dataTwo.isFormCompleted === 'true') {
												this.disableSubmit = true;
											}
										} else {
											this.disableSubmit = false;
											break;
										}
									}
								}
							});
					}
					// for(const data of this.pswPartDetailSave){
					// 	this.partNumberDetail.push(data.nextAuditPlan);
					// }
					this.display = false;
					this.messageService.add({severity: 'success', summary: 'Copied Successfully', life: 3000});
				});
			//this.partNumberDetail = this.pswPartDetailSave;
		});
	}

	retrieveDataView(selectedDataFromPartsTable) {
		//Added for US3853075
		if (selectedDataFromPartsTable.familyPswId !== null && selectedDataFromPartsTable.familyPswId !== undefined) {
			this.router.navigate(['/epsw-form/' + this.ppapPackageIdentifierId + '/' + this.mode + '/view' + '/'
			+ selectedDataFromPartsTable.ppapPackagePartsEntityID + '/' +selectedDataFromPartsTable.familyPswId]);
		} else {
			this.router.navigate(['/epsw-form/' + this.ppapPackageIdentifierId + '/' + this.mode + '/view' + '/'
			+ selectedDataFromPartsTable.ppapPackagePartsEntityID]);
		}
	}

	retrieveFamilyView() {
		this.familyId = sessionStorage.getItem('familyPswId');
		if (this.partNumberDetail.length > 1) {
			for (const data of this.partNumberDetail) {
				this.router.navigate(['/epsw-form/' + this.ppapPackageIdentifierId + '/' + this.mode + '/familyView' + '/'
				+ data.ppapPackagePartsEntityID]);
			}
			// else {
			// 	this.router.navigate(['/epsw-form/' + this.ppapPackageIdentifierId + '/' + this.mode + '/familyView']);
			// }
		}
		if (this.partNumberDetail.length === 1) {
			for (const data of this.partNumberDetail) {
				if(data.familyPswId !== null ){
					this.router.navigate(['/epsw-form/' + this.ppapPackageIdentifierId + '/' + this.mode + '/familyView'+ '/'
					+ data.ppapPackagePartsEntityID]);
				} else {
					this.router.navigate(['/epsw-form/' + this.ppapPackageIdentifierId + '/' + this.mode + '/view' + '/'
					+ data.ppapPackagePartsEntityID]);
				}
			}
		}
	}

	recallPage() {
		this.recallSubmission = true;
		if(this.recallSubmission && (this.recallComments === null || this.recallComments === undefined || !(this.recallComments.length > 0))){
			this.messageService.add({
				severity: 'info',
				summary: 'Please enter recall comments',
				life: 4000
			});
		} else {
			this.submitPage();
		}
	}

	submitPartsPackage(){
		if(!(this.tier2SavedFlag && this.cdsidSavedFlag && this.requiredElementsSavedFlag && this.commodityImgSavedFlag && this.disableSubmit)){
			this.displaySubmitParts = true;
			this.screen2ErrorMsg = [];
			this.screen3ErrorMsg = [];
			if(!this.tier2SavedFlag){
				this.screen2ErrorMsg.push('Upload Excel Tier 2 Details.') ;
			}
			if(!this.requiredElementsSavedFlag){
				this.screen2ErrorMsg.push('Update The Status of All Assessments in PPAP Required Elements.') ;
			}
			if(!this.cdsidSavedFlag){
				this.screen2ErrorMsg.push('Select Priority Supplier Yes or No and Save.');
			}
			if(!this.commodityImgSavedFlag){
				this.screen2ErrorMsg.push('Upload Commodity Image.');
			}
			if(!this.disableSubmit){
				this.screen3ErrorMsg.push('Make sure all applicable fields on PSW are updated.')
			}
		}else{
			this.displaySubmitParts=false;
			this.submitPage();
		}
	}

	submitPage() {
		const messageExists = false;
		// this.imageNotUploaded = false;
		this.cdsidNotSaved = false;
		this.tier2Saved = false;
		this.commodityImgSaved = false;
		console.log('Submitting Digital Ppap for Phase 0');
		// if (this.ppapLevelParts !== '1') {
			this.digitalPpapPackageService.checkIfTier2PPAPPackageSaved(this.ppapPackageIdentifierId).subscribe(tier2Response => {
				if (tier2Response === 0) {
					this.tier2Saved = true;
				}
			});
		// }
		this.epswHomepageService.retrieveCommodityImage(this.ppapPackageIdentifierId).subscribe(retrieveResp => {
			if (retrieveResp === null || (retrieveResp['commodityImg'] === null || undefined)) {
				this.commodityImgSaved = true;
			}
		});
		this.digitalPpapPackageService.checkIfPdStaCdsidSaved(this.ppapPackageIdentifierId).subscribe(response => {
			// if (commodityResponse['PpapPackageCommodityImage'].length === 0) {
			// 	this.imageNotUploaded = true;
			// 	// this.messageService.add({severity: 'error', summary: 'Please Upload Commodity Image in PPAP Package', life: 5000});
			// }
			const commodityResponse = response.mapOfStringList;
			commodityResponse['PpapPackageIdentifierEntity'].pdApprovalRequired = commodityResponse['PpapPackageIdentifierEntity']
				.pdApprovalRequired === '1'?'true':'false';
			if (commodityResponse['PpapPackageIdentifierEntity'].pdApprovalRequired === 'true') {
				if (commodityResponse['PpapPackageIdentifierEntity'].length === 0 || commodityResponse['PpapPackageIdentifierEntity'].length === 1) {
					this.cdsidNotSaved = true;
					// this.messageService.add({severity: 'error', summary: 'Please Provide CDSID for PD and STA in PPAP Package', life: 5000});
				}
			} else {
				if (commodityResponse['PpapPackageIdentifierEntity'].length === 0) {
					this.cdsidNotSaved = true;
					// this.messageService.add({severity: 'error', summary: 'Please Provide CDSID for STA in PPAP Package', life: 5000});
				}
			}
			console.log('PPAP Level  ' + this.ppapLevelParts);
			// if (this.ppapLevelParts !== '1' && this.chartRequiredElementsFlag !== true) {
			if (this.chartRequiredElementsFlag !== true) {
				this.checkPpapRequiredElement(messageExists);
			} else if (!messageExists) {
				this.savePartsStatus(messageExists);
			}
		});
	}

	checkPpapRequiredElement(messageExists: boolean) {
		this.requiredElementsNotSaved = false;
		this.digitalPpapPackageService.getPpapElementRequired(this.ppapPackageIdentifierId).subscribe(repo => {
			console.log('Ppap Required Check Data for Sumbit: ' + repo);
			if (repo.length > 0) {
				this.digitalPpapPackageService.getPackagePpapElementSubmitCheck(this.ppapPackageIdentifierId).subscribe(repos => {
						console.log('Ppap Required Response Check Data for Submit: ' + repos);
						if (repos.length === 1) {
							messageExists = true;
							this.requiredElementsSavedFlag=false;
							const msg = 'Please fill the following PPAP Required Element ' + repos.toString().split(',').join('\r\n');
							this.messageService.add({severity: 'error', summary: msg, life: 5000});
						}
						if (repos.length > 1) {
							messageExists = true;
							this.requiredElementsSavedFlag=false;
							const msg = 'Please fill the following PPAP Required Elements ' + repos.toString().split(',').join('\r\n');
							this.messageService.add({severity: 'error', summary: msg, life: 5000});
						}
						if (!messageExists) {
							this.savePartsStatus(messageExists);
						}
					}, error => {
						console.log('Error Log ' + error);
					}
				);
			} else if (repo.length <= 0) {
				this.requiredElementsNotSaved = true;
				if (!messageExists) {
					this.savePartsStatus(messageExists);
				}
				// this.messageService.add({severity: 'error',
				// summary: 'Please Complete PPAP Required Element and Response in PPAP Package', life: 5000});
			}
		}, error => {
			console.log('Error Log ' + error);
		});
	}

	savePartsStatus(messageExists: boolean) {
		let formCompleted: boolean;
		let messageExistsFromPPAPPackage = false;
		if (this.cdsidNotSaved && this.requiredElementsNotSaved) {
			messageExistsFromPPAPPackage = true;
			if (this.ppapLevelParts !== '1') {
				this.messageService.add({
					severity: 'info',
					summary: 'Please save the Parallel Approver CDSID and Required Elements in PPAP Package',
					life: 8000
				});
			} else {
				this.messageService.add({
					severity: 'info',
					summary: 'Please save the Parallel Approver CDSID in PPAP Package',
					life: 8000
				});
			}
		} else if (this.cdsidNotSaved && !this.requiredElementsNotSaved) {
			messageExistsFromPPAPPackage = true;
			this.messageService.add({
				severity: 'error',
				summary: 'Please save the Parallel Approver CDSID in PPAP Package',
				life: 6000
			});
		} else if (!this.cdsidNotSaved && this.requiredElementsNotSaved) {
			messageExistsFromPPAPPackage = true;
			if (this.ppapLevelParts !== '1') {
				this.messageService.add({
					severity: 'info',
					summary: 'Please save the Required Elements in PPAP Package',
					life: 6000
				});
			} else {
				this.messageService.add({
					severity: 'info',
					summary: 'Please save the Required Elements in PPAP Package',
					life: 6000
				});
			}
		} else if (this.cdsidNotSaved && this.requiredElementsNotSaved) {
			messageExistsFromPPAPPackage = true;
			if (this.ppapLevelParts !== '1') {
				this.messageService.add({
					severity: 'info',
					summary: 'Please save the Parallel Approver CDSID and Required Elements in PPAP Package',
					life: 6000
				});
			} else {
				this.messageService.add({
					severity: 'info',
					summary: 'Please save the Parallel Approver CDSID in PPAP Package',
					life: 6000
				});
			}
		} else if (this.cdsidNotSaved && !this.requiredElementsNotSaved) {
			messageExistsFromPPAPPackage = true;
			this.messageService.add({
				severity: 'error',
				summary: 'Please save the Parallel Approver CDSID in PPAP Package',
				life: 4000
			});
		} else if (!this.cdsidNotSaved && this.requiredElementsNotSaved) {
			messageExistsFromPPAPPackage = true;
			if (this.ppapLevelParts !== '1') {
				this.messageService.add({
					severity: 'info',
					summary: 'Please save the Required Elements in PPAP Package',
					life: 4000
				});
			} else {
				this.messageService.add({
					severity: 'info',
					summary: 'Please save the Required Elements in PPAP Package',
					life: 4000
				});
			}
		}
		if (!messageExistsFromPPAPPackage) {
			if (this.tier2Saved) {
				messageExistsFromPPAPPackage = true;
				this.messageService.add({
					severity: 'info',
					summary: 'Please Upload Excel Tier 2 Details Of Parts in PPAP Package',
					life: 4000
				});
			} else {
				if(this.commodityImgSaved){
					this.messageService.add({
						severity: 'info',
						summary: 'Please Upload Commodity Image',
						life: 4000
					});
				}else {
					this.digitalPpapPackageService.findAllPpapAssessmentResponse(this.ppapPackageIdentifierId).subscribe(ppapAssessmentResponse => {
						this.ppapAssessmentResponse = ppapAssessmentResponse;
						for (const data of this.partNumberDetail) {
							if (data.isFormCompleted === 'false') {
								formCompleted = false;
								break;
							} else {
								formCompleted = true;
							}
						}
						if (this.pswAccessControl.get('displayPpapPackageNextBtn')) {
							if (!formCompleted) {
								messageExists = true;
								this.messageService.add({
									severity: 'error',
									summary: 'Please fill all required fields in ePsw form',
									life: 4000
								});
							}
							if (!messageExists) {
								// for (let data of this.partNumberDetail) {
								// 	if (data.status === 'IN PROGRESS') {
								// 		data.status = 'AWAITING APPROVAL';
								// 	} else if (data.status !== 'AWAITING APPROVAL') {
								// 		statusList.push(data.status)
								// 	}
								// }
								this.confirmationService.confirm({
									message: this.recallSubmission ? 'Once recalled, PPAP status will be changed to In Progress. Are you sure you need to proceed with the recall?' : 'Ensure all required PPAP evidences are uploaded as per Ford Phased PPAP Requirements. Are you sure to submit now?',
									header: this.recallSubmission ? 'Recall Confirmation' : 'Submit Confirmation',
									icon: 'fa fa-info-circle',
									accept: () => {
										// if (statusList.length === 0) {
										for (const data of this.partNumberDetail) {
											data.ppapStaStatus = '';
											// data.ppapStaComments = '';
											data.ppapStaStatusOn = null;
											data.ppapStaStatusBy = '';
											if (this.pdStaApprovalRequiredL1) {
												data.staApprovalRequired = 'true';
											} else {
												data.staApprovalRequired = 'false';

											}
											//data.staApprovalRequired = '';
											data.ppapPdStatus = '';
											// data.ppapPdComments = '';
											data.ppapPdStatusOn = null;
											data.ppapPdStatusBy = '';
											data.pswStaStatus = '';
											// data.pswStaComments = '';
											data.pswStaStatusOn = null;
											data.pswStaStatusBy = '';
											data.pswPdStatus = '';
											// data.pswPdComments = '';
											data.pswPdStatusOn = null;
											data.pswPdStatusBy = '';
											data.submittedBy = sessionStorage.userName;
											data.submittedOn = new Date();
											data.submittedCdsid = sessionStorage.userId.toLocaleUpperCase();
											data.submittedEmailId = sessionStorage.userMailId;
											/*if (this.ppapLevelParts !== '1') {
												data.overallPpapStatus = 'AWAITING APPROVAL';
												data.overallPswStatus = 'AWAITING APPROVAL';
												data.status = 'AWAITING APPROVAL';
											} else {
												data.overallPpapStatus = 'LEVEL 1 SELF APPROVED';
												data.overallPswStatus = 'LEVEL 1 SELF APPROVED';
												data.status = 'LEVEL 1 SELF APPROVED';
											}*/

											// For Recall
											if ((this.recallSubmission) && (data.status.includes('AWAITING APPROVAL') || data.status.includes('LEVEL 1 SELF APPROVED'))) {
												data.recallComments = this.recallComments;
												data.recallDate = new Date();
												data.recallFlow = 'Yes';
												data.recallBy = sessionStorage.getItem('userId');
												console.log('Status: ' + data.status);
												console.log('Recall Comments: ' + data.recallComments);
												console.log('Recall Date: ' + data.recallDate);
												data.overallPpapStatus = 'IN PROGRESS';
												data.overallPswStatus = 'IN PROGRESS';
												data.status = 'IN PROGRESS';
											} else {
												data.recallFlow = 'No';
												if (this.ppapPackageIdentifier.pdApprovalRequired === '1') {
													data.overallPpapStatus = 'AWAITING APPROVAL';
													data.overallPswStatus = 'AWAITING APPROVAL';
													data.status = 'AWAITING APPROVAL';
												} else {
													if (this.ppapLevelParts === '1') {
														if (this.pdStaApprovalRequiredL1) {
															data.overallPpapStatus = 'AWAITING APPROVAL';
															data.overallPswStatus = 'AWAITING APPROVAL';
															data.status = 'AWAITING APPROVAL';
														} else {
															data.overallPpapStatus = 'LEVEL 1 SELF APPROVED';
															data.overallPswStatus = 'LEVEL 1 SELF APPROVED';
															data.status = 'LEVEL 1 SELF APPROVED';
														}
													} else {
														data.overallPpapStatus = 'AWAITING APPROVAL';
														data.overallPswStatus = 'AWAITING APPROVAL';
														data.status = 'AWAITING APPROVAL';
													}
												}
											}
										}
										// for (let data of this.ppapAssessmentResponse) {
										// 	data.staPdStatus = null;
										// 	data.staPdStatusOn = null;
										// 	data.staPdStatusBy = null;
										// 	data.staPdRemark = null;
										// }
										if (this.status === 'IN PROGRESS') {
											this.isSubmitted = true;
										}
										this.digitalPpapPackageService.savePartsStatus(this.partNumberDetail, this.isSubmitted, this.pdStaApprovalRequiredL1)
											.subscribe(response => {
												this.digitalPpapPackageService.savePpapElementRecords(this.ppapAssessmentResponse).subscribe(resp => {
													this.router.navigate(['/home-page']);
													this.messageService.add({
														severity: 'success',
														summary: this.recallSubmission ? 'Recalled Successfully' : 'Submitted Successfully',
														life: 3000
													});
												});
											});
										if (this.ppapLevelParts === '1' && this.ppapPackageIdentifier.pdApprovalRequired === '0') {
											this.supplierViewService.updateInterimPpapStatus(this.ppapPackageIdentifierId).subscribe(response => {
												console.log(response);
											});
										}
										// }
									},
									reject: () => {
									}
								});
							}
						}
					});
				}
			}
		}
	}

	selectedPartNumber(event: any) {
		if (this.partNumbers.length === 0) {
			this.isCopyButtonVisible = false;
		} else {
			this.isCopyButtonVisible = true;
		}
	}

	enableLoadPsw(rowData: any, load:boolean) {
		if(load){
			if(this.pswAccessControlForSupplier.get('supplierAccess')){
				if(rowData.status !== 'APPROVED' && rowData.status !== 'AWAITING APPROVAL' && rowData.status !== 'LEVEL 1 SELF APPROVED'){
					return true;
				} else {
					return false;
				}
			} else {
				return false;
			}
		} else {
			if(this.pswApproverAccessControl.get('approverConditionEnabled')){
				return true;
			} else {
				if(rowData.status === 'APPROVED' || rowData.status === 'AWAITING APPROVAL' || rowData.status === 'LEVEL 1 SELF APPROVED') {
					return true;
				}
			}
		}

	}
	//Alert message - US3738472
	isRecentChange(data): boolean {
		const b = data.partNumber;
		if (this.newPartList.includes(b)) {
			return false;//it needs to remove orange color because busineess requirement so will make return false instead of return true
		} else {
			return false;
		}
	}
	//Added for US3853075
	selectfamilyPswCheckbox(familyPswParts: boolean) {
		if (familyPswParts === true) {
			this.isShownFamilyPsw = true;
			this.showLoadPsw = false;
		} else {
			this.isShownFamilyPsw = false;
			this.showLoadPsw = true;
		}
		this.digitalService.getAllData(this.ppapPackageIdentifierId).subscribe(resp => {
			//let partNumberDetailPre: PpapPackageParts[]=null;
			this.partNumberDetail = resp.listOfPpapPackageParts;
			for(const data of this.partNumberDetail){
				if(data.familyPswId === null){
					this.checkedEntityIdList.push(data.ppapPackagePartsEntityID);
				}
				sessionStorage.setItem('checkedEntityIdList', this.checkedEntityIdList.toString());
			}
		});
	}
	selectCheck(rowdata: any,event: any) {
		if (event.length > 1) {
			this.showLoadPsw = false;
		} else {
			this.showLoadPsw = true;
		}
		if(event.length > 1) {
			this.isShownFamilyPsw = true;
		} else {
			this.isShownFamilyPsw = false;
		}
		if(event.length === 0) {
			this.familyPswParts = false;
		} else {
			this.familyPswParts = true;
		}
		// this.checkedEntityIdList.push(rowdata.ppapPackagePartsEntityID);
		// sessionStorage.setItem('checkedEntityIdList', this.checkedEntityIdList.toString());
	}
	showHeader() {
		if(this.familyPswParts === false){
			return this.pswAccessControlForSupplier.get('supplierAccess') && !this.pswApproverAccessControl.get('approverConditionEnabled');
		} else {
			return  this.showLoadPsw;
		}
	}
	//Added for US3853075
	showFamilyPswCol() {
		const count = this.partNumberDetail.filter(data => data.familyPswId > 0).length;
		if (count > 0) {
			return true;
		} else {
			return false;
		}
	}
	//Added for US3853075

	getCheckData(event: any,partNumberDetail: any) {
		this.checkedEntityIdList = [];
		if(event.length > 1) {
			this.isShownFamilyPsw = true;
			this.showLoadPsw = false;
		} else {
			this.isShownFamilyPsw = false;
			this.showLoadPsw = true;
		}
		for(const data of event){
			if(data.familyPswId === null){
				this.checkedEntityIdList.push(data.ppapPackagePartsEntityID);
			}
			sessionStorage.setItem('checkedEntityIdList', this.checkedEntityIdList.toString());
		}
	}

	showNotCmmsApprovedParts(){
		this.displayNotCmmsPartDlg = true;
	}
}
